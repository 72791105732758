import React, { useEffect, useRef, useState } from "react";
import "../StylesheetsMobile/LandingSectionMobile.css"
import "../fonts.css"
import introVideo from "../img/introvideo.mp4"

export default function LandingSectionMobile(props: LandingSectionProps) {
    const videoEnded = props.videoEnded;
    const setVideoEnded = props.setVideoEnded;
    const currentPosition = props.currentPosition;
    const locale = props.locale

    const [landingMainTextClass, setLandingMainTextClass] =
        useState<string>("")

    const [landingMainTextContainerClass, setLandingMainTextContainerClass] =
        useState<string>("")

    const [landingSubTextClass, setLandingSubTextClass] =
        useState<string>("")

    const objectRef = useRef<HTMLDivElement>(null)
    const landingRef = useRef<HTMLDivElement>(null)
    const scrollDownRef = useRef<HTMLDivElement>(null)

    const divDatabase = {
        landingTextDiv: {
            "en":
                <div>
                    <div className={landingSubTextClass}>with the power of MUSIC and</div>
                    <div className={landingSubTextClass}>ENTERTAINMENT from</div>
                    <div className={landingSubTextClass}>JAPAN to the WORLD</div>
                </div>,
            "jp":
                <div>
                    <div className={landingSubTextClass}>音楽とゲームの力で、</div>
                    <div className={landingSubTextClass}>世界中に</div>
                    <div className={landingSubTextClass}>最高のエクスペリエンスを。</div>
                </div>,
            "kr":
                <div>
                    <div className={landingSubTextClass}>음악과 게임의 힘으로</div>
                    <div className={landingSubTextClass}>전 세계에 최고의 엔터테이먼트를 선사합니다.</div>
                </div>
        }
    }


    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [currentPosition])

    useEffect(() => {

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }

    }, [window.innerWidth])

    function handleResize() {
        handleLandingMainText()
        handleLandingSubText()
    }

    function handleLandingMainText() {
        if (window.innerWidth > 500) {
            setLandingMainTextClass("landing-maintext-mobile")
            setLandingMainTextContainerClass("landing-maintext-container-mobile")
            setLandingSubTextClass("landing-subtext-mobile")
        }
        else {
            setLandingMainTextClass("landing-maintext-mobile-bp1")
            setLandingMainTextContainerClass("landing-maintext-container-mobile-bp1")
            setLandingSubTextClass("landing-subtext-mobile-bp1")
        }

    }

    function handleLandingSubText() {
        if (window.innerWidth > 600) {
            setLandingSubTextClass("landing-subtext-mobile")
        }
    }

    function handleVideoEnd() {
        setVideoEnded(true);
    }

    function handleScroll() {
        if (objectRef.current) {
            const factor = window.scrollY / 1500

            objectRef.current.style.borderTopLeftRadius = `${factor * 300}px`
            objectRef.current.style.borderTopRightRadius = `${factor * 300}px`


        }
        if (landingRef.current) {
            if ((window.outerHeight - currentPosition) + 120 < 0) {
                landingRef.current.style.opacity = "0";
            }
            else landingRef.current.style.opacity = "1";
        }
        if (scrollDownRef.current) {
            const factor = window.scrollY / window.innerHeight + 0.1
            scrollDownRef.current.style.opacity = (1 - factor).toString()
        }
    }
    return (
        <div className="landing-section-mobile" ref={landingRef}>
            {
                videoEnded &&
                <div className="main-container-mobile">
                    <div className="bg-object-mobile" ref={objectRef}></div>
                    <div className={landingMainTextContainerClass}>
                        <div className={landingMainTextClass}>REAL Excitement</div>
                        <div className={landingMainTextClass}>REAL Happiness</div>
                    </div>
                    <div className="landing-subtext-container-mobile">
                        {divDatabase.landingTextDiv[locale]}
                    </div>
                    <div className="scroll-down-mobile" ref={scrollDownRef}>SCROLL DOWN</div>
                </div>
            }
            <div className="video-container">
                <video playsInline className={videoEnded ? "intro-video-ended" : "intro-video"}
                    onEnded={handleVideoEnd}
                    autoPlay
                    muted
                    preload="auto">
                    <source src="https://video.wixstatic.com/video/55d5f4_11d29831be5d40019e120e85cc9e002c/1080p/mp4/file.mp4" type="video/mp4"></source>
                </video>
            </div>
        </div>
    )
}