import React, { forwardRef, useEffect, useState } from "react";
import "../StylesheetsMobile/GamesSectionMobile.css"
import "../fonts.css"
import controllerImage from "../img/_Promotion_Turntable.png"
import catchCopyImage from "../img/hp-GAMES_CC.png"
import gameLogoImage from "../img/_Promotion_Typo_with_revision.png"
import gamesBackgroundImage from "../img/hp-games_BG.png"

const GamesSectionMobile = forwardRef<HTMLDivElement, GamesSectionProps>((props, ref) => {

    const locale = props.locale
    
    const [descTextClass, setDescTextClass] = useState<string>('')

    const divDatabase = {
        gamesDescDiv: {
            "en":
                <div className="game-description-container">
                    <div className={descTextClass}>A next generation music game where 2 songs and 2 lanes converge!</div>
                    <div className={descTextClass}>Featuring songs composed by well-known artists from various music games,</div>
                    <div className={descTextClass}>dive into the new era of music gaming with MASH VP! Re:Vision!</div>
                </div>,
            "jp":
                <div className="game-description-container">
                    <div className={descTextClass + " jp-text-description"}>「MASH VP! Re:VISION」は、</div>
                    <div className={descTextClass + " jp-text-description"}>２つのルートと音楽が融合する</div>
                    <div className={descTextClass + " jp-text-description"}>MASH UPリズムゲーム！</div>
                    <div className={descTextClass + " jp-text-description"}>様々な音ゲーに携わってきた</div>
                    <div className={descTextClass + " jp-text-description"}>有名アーティスト楽曲はもちろん、</div>
                    <div className={descTextClass + " jp-text-description"}>楽曲の掛け合わせを楽しめる”マッシュアップ”で、</div>
                    <div className={descTextClass + " jp-text-description"}>音ゲーの新たな世界へ！</div>
                </div>,
            "kr":
                <div className="game-description-container">
                    <div className={descTextClass}>[MASH VP! Re:VISION]은 두 개의 라인과 음악이 융합되는 MASH UP 리듬 게임입니다! 다양한 음악 게임에 참여해온 유명 아티스트들의 곡은 물론, 곡들을 결합해 즐길 수 있는 ‘매쉬업’ 모드를 통해 새로운 음악 게임 세계로 여러분을 초대합니다!</div>
                </div>
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResizing)

        return () => {
            window.removeEventListener("resize", handleResizing)
        }
    }, [window.innerWidth])

    useEffect(() => {
        handleResizing()
    }, [])

    function handleResizing() {
        if (window.innerWidth > 700) {
            setDescTextClass("game-description-text-mobile")
        }
        else {
            setDescTextClass("game-description-text-mobile-bp1")
        }
    }
    
    return (
        <div className="games-section-mobile" ref={ref}>
            <div className="background-image-mobile">
                <img className="background-image-img-mobile" src={gamesBackgroundImage}></img>
            </div>
            <div className="controller-image-container-mobile">
                <img className="controller-image-mobile" src={controllerImage}></img>
            </div>
            <div className="games-section-title-mobile">OUT NOW!</div>
            <video playsInline className="video-iframe-mobile" loop autoPlay
                muted>
                <source src="https://video.wixstatic.com/video/55d5f4_c753e11fc3b946ccbfa269098839b719/1080p/mp4/file.mp4" type="video/mp4"></source>
            </video>
            <a href={"/mashup" +"?locale="+ locale} className="game-icon-container-mobile">
                <img className="game-icon-mobile" src={gameLogoImage}></img>
            </a>
            <div className="click-here-mobile">▲ CLICK HERE FOR DETAILS ▲</div>
            <div className="game-catchcopy-container-mobile">
                <img src={catchCopyImage} className="game-catchcopy-img-mobile"></img>
            </div>
            {divDatabase.gamesDescDiv[locale]}
        </div>
    )

})

export default GamesSectionMobile