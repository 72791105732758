import React from "react";
import "../Stylesheets/RecruitSection.css"

export default function RecruitItemContainer(props: RecruitIContainerProps) {

    const locale = props.locale 
    const titleText = props.post.positionTitle
    const dateText = props.post.date
    const jobDescriptionText = props.post.jobDescription
    const requiredSkillsText = props.post.requiredSkills
    const preferredSkillsText = props.post.preferredSkills

    const textDatabase = {
        jobDescText: {
            "en" : "Job Description",
            "jp": "業務内容",
        },
        requiredSkillsText: {
            "en" : "Required Experience",
            "jp": "必須スキル・経験",
        }, 
        preferredSkillsText: {
            "en" : "Preferred Experience",
            "jp": "歓迎するスキル・経験",
        }
    }

    return (
        <div className="recruit-item-container">
            <div className="recruit-icontainer-top">
                <div className="position-title">
                    <div className="position-title-text">{titleText}</div>
                </div>
            </div>
            <div className="recruit-icontainer-bottom">
                <div className="recruit-detail-box">
                    <div className="recruit-bottom-title">{textDatabase.jobDescText[locale]}</div>
                    <div className="recruit-detail-desc-text" dangerouslySetInnerHTML={{__html: jobDescriptionText}}></div>
                </div>
                <div className="recruit-detail-box">
                    <div className="recruit-bottom-title">{textDatabase.requiredSkillsText[locale]}</div>
                    <div className="recruit-detail-desc-text" dangerouslySetInnerHTML={{__html: requiredSkillsText}}></div>
                </div>
                <div className="recruit-detail-box">
                    <div className="recruit-bottom-title">{textDatabase.preferredSkillsText[locale]}</div>
                    <div className="recruit-detail-desc-text" dangerouslySetInnerHTML={{__html: preferredSkillsText}}></div>
                </div>
            </div>
        </div>
    )
}