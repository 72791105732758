import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../Stylesheets/RecruitSection.css"
import RecruitItemContainer from "./RecruitItemContainer";
import { items } from "@wix/data"
import { createClient, OAuthStrategy } from "@wix/sdk-react";
import { useSearchParams } from "react-router-dom";
import HeaderMobile from "../ComponentsMobile/HeaderMobile";
import Footer from "./Footer";
import RecruitItemContainerMobile from "../ComponentsMobile/RecruitItemContainerMobile";
import FooterMobile from "../ComponentsMobile/FooterMobile";

export default function RecruitPage() {

    const [wixData, setWixData] = useState<Array<RecruitPost>>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [locale, setLocale] = useState<Locale>(stringToLocale("jp"))
    const [spanTextClass, setSpanTextClass] = useState<string>("")
    const [emailTextClass, setEmailTextClass] = useState<string>("")
    const [currentWidth, setCurrentWidth] = useState<number>(0)
    const wixApiKey = process.env.REACT_APP_WIX_CLIENT_ID;

    const textDatabase = {
        titleText: {
            "en": "OPEN POSITIONS",
            "jp": "募集職種",
            "kr": "채용 정보"
        },
        recruitLinkText: {
            "en": "Contact us at",
            "jp": "ご応募の際は",
            "kr": "지원 희망자는"
        },
        recruitLinkText2: {
            "en": "if you wish to apply!",
            "jp": "こちらまでご連絡ください",
            "kr": "으로 연락해 주세요!"
        }
    }


    useEffect(() => {
        fetchData(locale)
        handleResize()
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [window.innerWidth])


    useEffect(() => {

        stringToLocale(checkLocale())
        fetchData(locale)

    }, [locale])

    function handleResize() {
        setCurrentWidth(window.innerWidth)

        if (window.innerWidth > 1820)
        {
            setSpanTextClass("span-text")
            setEmailTextClass("email-text")
            return
        }
        if (window.innerWidth > 1024)
        {
            setSpanTextClass("span-text-bp1")
            setEmailTextClass("email-text-bp1")
            return
        }

        if (window.innerWidth > 800)
        {
            setSpanTextClass("span-text-mobile")
            setEmailTextClass("email-text-mobile")
            return
        }
        else {
            setSpanTextClass("span-text-mobile-bp1")
            setEmailTextClass("email-text-mobile-bp1")
            return 
        }
    }

    function stringToLocale(stringLocale: string): Locale {
        switch (stringLocale)
        {
          case "en": 
          case "jp":
          case "kr": 
          return stringLocale
          default: 
          return "en";
        }
      }

    async function fetchData(locale: string) {
        if (wixApiKey)
        {
            const wixClient = createClient({
                modules: { items },
                auth: OAuthStrategy({ clientId: wixApiKey })
            })
            let dataCollectionId = ""
            if (locale === "en") {
                dataCollectionId += "RecruitPosts-EN"
            }
            else {
                dataCollectionId += "RecruitPosts-JP"
            }
            const dataItemsList = await wixClient.items
                .queryDataItems({
                    dataCollectionId
                })
                .find()
            const newWixData: Array<RecruitPost> = []
            dataItemsList.items.map(post => {
                if (post.data) {
                    const newPost: RecruitPost =
                    {
                        positionTitle: post.data.title,
                        date: post.data.date,
                        jobDescription: post.data.jobDescription,
                        requiredSkills: post.data.requiredSkills,
                        preferredSkills: post.data.preferredSkills
                    }
                    newWixData.unshift(newPost)
                }
                else console.error("Bad data sent")
            })
            setWixData(newWixData)
        }
        else {
            console.error("client id invalid")
        }
    }

    function checkLocale(): string {
        const urlParam = searchParams.get("locale")
        const validParams = ["en", "jp"]
        if (urlParam) {
            if (validParams.includes(urlParam)) {
                setSearchParams({ locale: locale })
                return urlParam
            }
            else {
                setSearchParams({ locale: "en" })
                return "en"
            }
        }
        else {
            setSearchParams({ locale: "en" })
            return "en"
        }
    }

    return (
        currentWidth > 1100 ?
            <div className="recruit-page">
                <Header logoOverride="" focusedArea="recruit" isMobileAndNotMain={false} locale={locale} setLocale={setLocale} videoEnded={true} />
                <div className="recruit-section">
                    <div className="recruit-title-email-container">
                        <div className="recruit-section-title">{textDatabase.titleText[locale]}</div>
                        <span className={spanTextClass}>{textDatabase.recruitLinkText[locale]}</span>
                        <span className={emailTextClass}>contact@axtormjapan.com</span>
                        <span className={spanTextClass}>{textDatabase.recruitLinkText2[locale]}</span>
                    </div>
                    <div className="recruit-items-box">
                        {wixData.map(post => {
                            return <RecruitItemContainer currentWidth={currentWidth} locale={locale} post={post} />
                        })}
                    </div>
                </div>
                <Footer locale={locale} />
            </div>
            :
            <div className="recruit-page-mobile">
                <HeaderMobile logoOverride="" focusedArea="recruit" isMobileAndNotMain={true} locale={locale} setLocale={setLocale} videoEnded={true} />
                <div className="recruit-section-mobile">
                    <div className="recruit-title-email-container-mobile">
                        <div className="recruit-section-title-mobile">{textDatabase.titleText[locale]}</div>
                        <span className={spanTextClass}>{textDatabase.recruitLinkText[locale]}</span>
                        <span className={emailTextClass}>contact@axtormjapan.com</span>
                        <span className={spanTextClass}>{textDatabase.recruitLinkText2[locale]}</span>
                    </div>
                    <div className="recruit-items-box-mobile">
                        {wixData.map(post => {
                            return <RecruitItemContainerMobile currentWidth={currentWidth} locale={locale} post={post} />
                        })}
                    </div>
                </div>
                <FooterMobile locale={locale} />
            </div>
    )
}