import React, { useEffect, useState } from "react";
import "../Stylesheets/Footer.css"
import footerIcon from "../img/Logo_AXTORM-white.png"


export default function FooterMobile(props: FooterProps) {

    const locale = props.locale
    const [infoLineClass, setInfoLineClass] = useState<string>("")

    useEffect(() => {
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [window.innerWidth])

    const textDatabase = {
        locationText: {
            "en": "Minato-ku, Tokyo, Japan", 
            "jp": "東京都港区",
            "kr": "위치: 일본 도쿄도 미나토구"
        },
        foundedText: {
            "en": "Founded October 2023", 
            "jp": "設立日: 2023年（令和5年）10月1日", 
            "kr":"설립일: 2023년 10월"
        },
        accessibleText: {
            "en": "Accessible from JR Tamachi Station (10 Minute Walk)", 
            "jp": "アクセス: JR山手線/京浜東北線「田町駅」　徒歩10分",
            "kr": "오시는 길: JR 다마치역에서 도보 10분 거리"
        },
    }

    function handleResize() {   
        if (window.innerWidth > 450) 
        {
            setInfoLineClass("info-line-mobile")
        }
        else 
        {
            setInfoLineClass("info-line-mobile-bp1")
        }
    }

    return (
        <div className="footer-section-mobile">
            <div className="icon-with-info-mobile">
                <div className="footer-icon-mobile">
                    <img className="footer-icon-img" src={footerIcon}></img>
                </div>
                {/*<div className="company-info-container-mobile">
                    <div className={infoLineClass}>{textDatabase.locationText[locale]}</div>
                    <div className={infoLineClass}>{textDatabase.foundedText[locale]}</div>
                    <div className={infoLineClass}>{textDatabase.accessibleText[locale]}</div>
                </div>*/}
            </div>
            <div className="misc-footer-info-mobile">
                <div className={infoLineClass}>© 2024 by AXTORM Co., Ltd. All Rights Reserved.</div>
            </div>
        </div>
    )
}