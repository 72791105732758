import React, { useEffect, useState } from "react";
import "../Stylesheets/CompanySection.css"
import "../fonts.css"
import musicLogoEn from "../img/logo_AXTORM-MUSIC-e.png"
import gamesLogoEn from "../img/logo_AXTORM-GAMES-e.png"
import musicLogoJp from "../img/logo_AXTORM-MUSIC-j.png"
import gamesLogoJp from "../img/logo_AXTORM-GAMES-j.png"
import musicLogoKr from "../img/logo_AXTORM-MUSIC-k.png"
import gamesLogoKr from "../img/logo_AXTORM-GAMES-k.png"


export default function CompanySection(props: CompanySectionProps) {

    const [bottomTextClass, setBottomTextClass] = useState<string>("company-bottom-text")
    const loopVideoSource = "https://video.wixstatic.com/video/55d5f4_b86abe49278b4d1e93730fdf3d488ff7/1080p/mp4/file.mp4"
    const locale = props.locale

    const divDatabase = {
        descText: {
            "en":
                <div className="bottom-text-company-container">
                    <div className={bottomTextClass}>Through AXTORM Music and AXTORM Games, </div>
                    <div className={bottomTextClass}>AXTORM strives to promote creative approaches with varied perspectives</div>
                    <div className={bottomTextClass}>in order to produce forms of entertainment that customers around the world can enjoy.</div>
                </div>,
            "jp":
                <div className="bottom-text-company-container">
                    <div className={bottomTextClass}>AXTORM は【AXTORM MUSIC】と【AXTORM GAMES】２つの事業によって</div>
                    <div className={bottomTextClass}>世界中のカスタマーが楽しめるエンタテインメント創出のために、</div>
                    <div className={bottomTextClass}>多様な視点や創造性を尊重したクリエイティブなアプローチを推進します。</div>
                </div>,
            "kr":
                <div className="bottom-text-company-container">
                    <div className={bottomTextClass}>AXTORM은 【AXTORM MUSIC】과 【AXTORM GAMES】 두 가지 사업을 통해 전 세계 고객이 즐길 수 있는 엔터테인먼트를 창출하기 위해 다양한 관점과 창의성을 존중하는 창조적인 접근을 추구합니다.</div>
                </div>
        },
        logosDiv: {
            "en":
                <div className="logo-label-container">
                    <div className="logo-label">
                        <img className="logo-label-img" src={musicLogoEn}></img>
                    </div>
                    <div className="logo-label">
                        <img className="logo-label-img" src={gamesLogoEn}></img>
                    </div>
                </div>,
            "jp":
                <div className="logo-label-container">
                    <div className="logo-label">
                        <img className="logo-label-img" src={musicLogoJp}></img>
                    </div>
                    <div className="logo-label">
                        <img className="logo-label-img" src={gamesLogoJp}></img>
                    </div>
                </div>,
            "kr":
                <div className="logo-label-container">
                    <div className="logo-label">
                        <img className="logo-label-img" src={musicLogoKr}></img>
                    </div>
                    <div className="logo-label">
                        <img className="logo-label-img" src={gamesLogoKr}></img>
                    </div>
                </div>
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleTextClasses)

        return () => {
            window.removeEventListener("resize", handleTextClasses)
        }
    }, [window.innerWidth])

    useEffect(() => {
        handleTextClasses()
    }, [])

    function handleTextClasses() {
        if (window.innerWidth > 1280) {
            setBottomTextClass("company-bottom-text")
        }
        else if (window.innerWidth > 900) {
            setBottomTextClass("company-bottom-text-bp1")
        }
        else {
            setBottomTextClass("company-bottom-text-bp2")
        }
    }


    return (
        <div className="company-section">
            <div className="video-background-company">
                <video className="background-video-element" autoPlay loop muted>
                    <source src={loopVideoSource} type="video/mp4"></source>
                </video>
            </div>
            <div className="company-container">
                {divDatabase.logosDiv[locale]}
            </div>
            {divDatabase.descText[locale]}
        </div>
    )
}