import React from "react";
import "../Stylesheets/Footer.css"
import footerIcon from "../img/Logo_AXTORM-white.png"


export default function Footer(props: FooterProps) {

    const locale = props.locale

    const textDatabase: TextDatabase = {
        locationText: {
            "en": "Minato-ku, Tokyo, Japan", 
            "jp": "東京都港区",
            "kr": "위치: 일본 도쿄도 미나토구"
        },
        foundedText: {
            "en": "Founded October 2023", 
            "jp": "設立日: 2023年（令和5年）10月1日", 
            "kr":"설립일: 2023년 10월"
        },
        accessibleText: {
            "en": "Accessible from JR Tamachi Station (10 Minute Walk)", 
            "jp": "アクセス: JR山手線/京浜東北線「田町駅」　徒歩10分",
            "kr": "오시는 길: JR 다마치역에서 도보 10분 거리"
        },
    }

    return (
        <div className="footer-section">
            <div className="icon-with-info">
                <div className="footer-icon">
                    <img className="footer-icon-img" src={footerIcon}></img>
                </div>
                {/*<div className="company-info-container">
                    <div className="info-line">{textDatabase.locationText[locale]}</div>
                    <div className="info-line">{textDatabase.foundedText[locale]}</div>
                    <div className="info-line">{textDatabase.accessibleText[locale]}</div>
                </div>*/}
            </div>
            <div className="misc-footer-info">
                <div className="info-line">© 2024 by AXTORM Co., Ltd. All Rights Reserved.</div>
            </div>
        </div>
    )
}