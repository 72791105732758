import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainHomePage from './Components/MainHomePage';
import RecruitPage from './Components/RecruitPage';
import NewsPage from './Components/NewsPage';
import MashupPage from './Components/MashupPage';
import PoliciesPage from './Components/PoliciesPage';

export default function App() {

  return (
    <Routes>
      <Route path="/" element={<MainHomePage />}/>
      <Route path="/recruit" element={<RecruitPage />}/>
      <Route path="/news" element={<NewsPage />}/> 
      <Route path="/mashup" element={<MashupPage />}/>
        <Route path="/guidelines" element={<PoliciesPage/>}/>    
    </Routes>
  );
}

