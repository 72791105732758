import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../Stylesheets/AboutUsSection.css"
import "../fonts.css"


const AboutUsSection = forwardRef<HTMLDivElement, AboutUsProps>((props, ref: React.RefObject<HTMLDivElement>) => {

    const locale = props.locale
    const currentPosition = props.currentPosition
    const closeLimit = 700

    const [bottomPosition, setBottomPosition] = useState<number>(0)
    const [componentOpacity, setComponentOpacity] = useState<number>(1)
    const [titleTextClass, setTitleTextClass] = useState<string>("about-title-text")
    const [descTextClass, setDescTextClass] = useState<string>("about-desc-text")

    const divStyle: React.CSSProperties = {
        backgroundColor: "#c2c2c2",
        position: "relative",
        zIndex: "10",
        borderTopLeftRadius: "33px",
        borderTopRightRadius: "33px",
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        overflow: "hidden",
        opacity: componentOpacity == 100 ? "100%" : componentOpacity.toString()
    }

    const divDatabase = {
        firstTitleSection: {
            "en":
                <div>
                    <div className={titleTextClass}>Magical experiences through traditional, </div>
                    <div className={titleTextClass}>yet innovative music games and audio entertainment</div>
                </div>,
            "jp":
                <div>
                    <div className={titleTextClass}>トラッド＆革新的な音楽ゲームと</div>
                    <div className={titleTextClass}>音楽エンタテインメントで </div>
                    <div className={titleTextClass}>最高の体験を</div>
                </div>,
            "kr":
                <div>
                    <div className={titleTextClass}>전통적이면서도 혁신적인 음악 게임과 오디오 엔터테인먼트를 통해 마법 같은 경험을 제공합니다.</div>
                </div>
        },
        firstDescSection: {
            "en":
                <div className="about-us-desc-container">
                    <div className={descTextClass}>We are a global entertainment company that not only develops rhythm games, but also specializes in music production.</div>
                    <div className={descTextClass}>Our mission is to invoke emotion through creating and releasing music and music games. </div>
                    <div className={descTextClass}>Through the power of the ingenuity of creators around the world, we create music games overflowing with genres and styles that pay homage to the traditional genre.</div>
                    <div className={descTextClass}>We strive to provide the best entertainment experience to our customers and fans alike.</div>
                </div>,
            "jp":
                <div className="about-us-desc-container">
                    <div className={descTextClass}>リズムアクションゲームの開発 / 運営とミュージックプロダクションを展開する</div>
                    <div className={descTextClass}>グローバルエンタテインメント企業です。</div>
                    <div className={descTextClass}>私たちのミッションは、</div>
                    <div className={descTextClass}>世界中のクリエイター達の創造力によって、</div>
                    <div className={descTextClass}>トラッド重視の多様なジャンルとスタイルに溢れた</div>
                    <div className={descTextClass}>リズムアクションゲーム、音楽で、新たな体験と感動を創造すること。</div>
                    <div className={descTextClass}>お客様へ、最高のエンタテインメント体験を提供することをお約束します。</div>
                </div>,
            "kr":
                <div className="about-us-desc-container">
                    <div className={descTextClass}>우리는 리듬 게임 개발뿐만 아니라 음악 제작에도 특화된 글로벌 엔터테인먼트 회사입니다. 우리의 미션은 음악과 음악 게임을 창작하고 발매함으로써 감동을 불러일으키는 것입니다. 전 세계 창작자들의 창의력을 바탕으로 다양한 장르와 스타일이 넘치는 음악 게임을 제작하며, 고객과 팬 모두에게 최고의 엔터테인먼트 경험을 제공하기 위해 끊임없이 노력하고 있습니다.</div>
                </div>
        },
        gainenBox: {
            "en":
                <div className="gainen-box">
                    <div className={titleTextClass}>Company Info</div>
                    <div className="about-us-desc-container">
                        <div className={descTextClass}>Company Name: 株式会社AXTORM</div>
                        <div className={descTextClass}>Location: Minato-ku, Tokyo, Japan</div>
                        <div className={descTextClass}>Founded: October 2023</div>
                        <div className={descTextClass}>Access: 10 minute walk from JR Tamachi Station</div>
                    </div>
                </div>,
            "jp":
                <div className="gainen-box">
                    <div className={titleTextClass}>会社概要</div>
                    <div className="about-us-desc-container">
                        <div className={descTextClass}>会社名: 株式会社AXTORM（アクストーム）</div>
                        <div className={descTextClass}>所在地: 東京都港区</div>
                        <div className={descTextClass}>設立日: 2023年（令和5年）10月1日</div>
                        <div className={descTextClass}>アクセス: JR山手線/京浜東北線「田町駅」　徒歩10分</div>
                    </div>
                </div>,
            "kr":
                <div className="gainen-box">
                    <div className={titleTextClass}>회사 정보</div>
                    <div className="about-us-desc-container">
                        <div className={descTextClass}>회사명: 주식회사 AXTORM </div>
                        <div className={descTextClass}>위치: 일본 도쿄도 미나토구</div>
                        <div className={descTextClass}>설립일: 2023년 10월</div>
                        <div className={descTextClass}>오시는 길: JR 다마치역에서 도보 10분 거리</div>
                    </div>
                </div>
        }

    }


    useEffect(() => {
        if (ref) {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const bottom = rect.top + rect.height + window.scrollY
                setBottomPosition(bottom)

                if (bottomPosition - currentPosition < closeLimit) {
                    const setOpacity = (bottomPosition - currentPosition) / closeLimit
                    if (setOpacity >= 0) {
                        setComponentOpacity(setOpacity)
                    }
                }
                else setComponentOpacity(1)
            }
        }
    }, [window.scrollY])

    useEffect(() => {
        window.addEventListener("resize", handleTextClasses)

        return () => {
            window.removeEventListener("resize", handleTextClasses)
        }
    }, [window.innerWidth])

    useEffect(() => {
        handleTextClasses()
    }, [])

    function handleTextClasses() {
        if (window.innerWidth > 1600) {
            setTitleTextClass("about-title-text")
            setDescTextClass("about-desc-text")
        }
        else if (window.innerWidth > 1300) {
            setTitleTextClass("about-title-text-bp1")
            setDescTextClass("about-desc-text-bp1")
        }
        else if (window.innerWidth > 1100) {
            setTitleTextClass("about-title-text-bp2")
            setDescTextClass("about-desc-text-bp2")
        }
        else {
            setTitleTextClass("about-title-text-bp3")
            setDescTextClass("about-desc-text-bp3")
        }

    }


    return (
        <div className="about-us-section" ref={ref} style={divStyle}>
            <div className="about-text-container">
                {divDatabase.firstTitleSection[locale]}
                <div className="about-sub-title-text">AXTORM's VISION</div>
                {divDatabase.firstDescSection[locale]}
                {divDatabase.gainenBox[locale]}
            </div>
        </div>
    )
})

export default AboutUsSection