import React, { useState } from "react";
import "../StylesheetsMobile/MobileMenu.css"
import mobileIconBg from "../img/hp-logo1.png"
import { Link } from "react-router-dom";
import LanguageSelectorMobile from "./LanguageSelectorMobile";


export default function MobileMenu(props: MobileMenuProps) {
    const locale = props.locale
    const setLocale = props.setLocale
    const focusedArea = props.focusedArea
    const [isOpen, setIsOpen] = useState<boolean>(false)

    function handleMenuClick() {
        setIsOpen(!isOpen)
    }

    return (
        isOpen ?
            <div className="mobile-menu">
                <div className="mobile-bg-icon">
                    <img className="mobile-bg-icon-img" src={mobileIconBg}></img>
                </div>
                <div className="mobile-menu-close-button" onClick={handleMenuClick}>
                    <div className="close-button-line"></div>
                    <div className="close-button-line-2"></div>
                </div>
                <div className="mobile-menu-items-container">
                    <Link className="mobile-menu-item" to={"/" + "?locale=" + locale + "#aboutUs"} onClick={handleMenuClick}><div>ABOUT US</div></Link>
                    <Link className="mobile-menu-item" to={"/" + "?locale=" + locale + "#music"} onClick={handleMenuClick}><div>MUSIC</div></Link>
                    <Link className="mobile-menu-item" to={"/" + "?locale=" + locale + "#games"} onClick={handleMenuClick}><div>GAMES</div></Link>
                    <a className={focusedArea === "news" ? "mobile-menu-item focused-area" : "mobile-menu-item"} href={"/news" + "?locale=" + locale}>NEWS</a>
                    <a className={focusedArea === "recruit" ? "mobile-menu-item focused-area" : "mobile-menu-item"} href={"/recruit" + "?locale=" + locale}>RECRUIT</a>
                    <a className={focusedArea === "policies" ? "mobile-menu-item focused-area" : "mobile-menu-item"}
                        href={"/guidelines" + "?locale=" + locale}>GUIDELINES</a>
                </div>
                <div className="language-container-mobile">
                    {focusedArea !== "recruit" && <LanguageSelectorMobile locale={locale} setLocale={setLocale} />}
                </div>
            </div>
            :
            <div className="mobile-menu-closed" onClick={handleMenuClick}>
                <div className="icon-line"></div>
                <div className="icon-line"></div>
                <div className="icon-line"></div>
            </div>
    )
}