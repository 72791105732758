import React from "react";
import "../Stylesheets/NewsSection.css"


export default function NewsItemContainer(props: NewsItemContainerProps) {

    const currentWidth = props.currentWidth
    const locale = props.locale
    const titleText = props.post.title
    const descriptionText = props.post.description
    const dateText = props.post.date
    const imagePath = props.post.image
    const urlText = props.post.url
    const urlDetail = props.post.urlDetail

    const textDatabase: TextDatabase = {
        defaultText: {
            "en": "Click here for more details",
            "jp": "詳しくはこちら"
        }
    }

    return (
        <div className={currentWidth > 600 ? "news-item-container" : "news-item-container-mobile"}>
            <div className="icon-section-icontainer">
                <img className="icontainer-icon-img" src={imagePath}></img>
            </div>
            <div className={currentWidth > 600 ? "title-date-container" : "title-date-container-mobile"}>
                <div className={currentWidth > 600 ? "title-section-text" : "title-section-text-mobile"}>{titleText}</div>
                <div className="date-section-text">{dateText}</div>
            </div>
            <div className="description-container">
                <div className={currentWidth > 600 ? "news-description-text" : "news-description-text-mobile" }>{descriptionText}</div>
            </div>
            {urlText &&<a href={urlText} target="_blank" className="news-url-text">{urlDetail ? urlDetail : textDatabase.defaultText[locale]}</a>}
        </div>
    )
}