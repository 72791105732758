import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import "../Stylesheets/PoliciesPage.css"
import HeaderMobile from "../ComponentsMobile/HeaderMobile";
import FooterMobile from "../ComponentsMobile/FooterMobile";

export default function PoliciesPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [locale, setLocale] = useState<string>(searchParams.get("locale") || "en")
    const [descTextClass, setDescTextClass] = useState<string>("policy-desc-text")
    const [currentWidth, setCurrentWidth] = useState<number>(0)
    
    useEffect(() => {
        window.addEventListener("resize", handleTextClasses)

        return () => {
            window.removeEventListener("resize", handleTextClasses)
        }
    }, [window.innerWidth])

    useEffect(() => {
        handleTextClasses()
    }, [])
    
    const divDatabase = {
        contentsPolicy:{
            "en": <div className="policy-section">
                <div className="policy-title">Contents Usage Guidelines</div>
                <div className={descTextClass}>AXTORM welcomes everyone who wants to make their own content using ideas
                    and content from MASH VP! Re:VISION! The purpose of these guidelines is to provide standards for the
                    use of game content for commercial and non-commercial purposes, while also allowing us to expand the
                    MASH VP! community and encourage the creativity of those in our community.
                </div>
                <div className="policy-section-title">1. Non-Commercial Use of Content</div>
                <div className={descTextClass}>You are free to engage in the following activities for non-commercial
                    purposes：
                </div>
                <li className={descTextClass}>The creation and sharing of fan art, illustrations, novels, and other
                    fan-created content
                </li>
                <li className={descTextClass}>The creation of videos or reviews containing in game screenshots and other
                    in-game content
                </li>
                <li className={descTextClass}>Posting and sharing game-related content on social networking sites and
                    blogs for non-commercial purposes
                </li>
                <div className="policy-section-title">2. Commercial Use of Content</div>
                <div
                    className={descTextClass}>Content creation using the game that generates revenue is also permitted
                    in the following cases:
                </div>
                <li className={descTextClass}>Streaming and video production for advertising revenue on platforms such
                    as YouTube and Twitch
                </li>
                <li className={descTextClass}>Earning revenue through fan donations and subscriptions is allowed.</li>
                <li className={descTextClass}>Other cases may be allowed, but please contact AXTORM prior for
                    approval.
                </li>
                <div className="policy-section-title">3. Prohibited Uses</div>
                <div className={descTextClass}>The following actions are strictly prohibited:</div>
                <li className={descTextClass}>Unauthorized modification of the game or creation of content related to or
                    using unofficial servers to play the game.
                </li>
                <li className={descTextClass}>Content that contains political, religious, violent, or discriminatory
                    messages.
                </li>
                <li className={descTextClass}>Any activity that infringes on copyrights held by other parties, or
                    violates the rights of others.
                </li>
                <li className={descTextClass}>Posts that contain spoilers about key elements of the game, such as
                    storyline, music, or any unreleased content.
                </li>
                <div className="policy-section-title">4. Special Provisions for the Commercial Use of Gameplay Videos</div>
                <li className={descTextClass}>YouTube、Twitch
                    If you wish to generate advertising revenue through partner programs such as YouTube, Twitch, or other similar platforms, you are permitted to produce and post videos of your gameplay for commercial purposes. However, the content posted must not infringe on any of the points listed in Section 3.</li>
                <div className="policy-section-title">5. Content Usage Precautions</div>
                <li className={descTextClass}>If any violation of these guidelines is discovered, we reserve the right to request that the content in question be removed.</li>
                <li className={descTextClass}>The guidelines are subject to change without notice, and any changes will be immediately posted to this site.</li>
                <div className="policy-section-title">6. Questions Regarding Content Usage</div>
                <div className={descTextClass}>If you have any questions about these guidelines, or have questions about a specific use case you have in mind, contact us at support@axtormjapan.com.</div>
                <div className={descTextClass}>We have native English speakers on staff, so feel free to email in English.</div>
            </div>,
            "jp": <div className="policy-section">
                <div className="policy-title">コンテンツ使用に関するガイドライン</div>
                <div className={descTextClass}>AXTORMが開発したリズムゲーム 「MASH VP! Re:VISION」
                    のコンテンツを使用して二次創作活動を行う皆様を歓迎します。このガイドラインは、営利目的および非営利目的でゲームコンテンツを使用する際の基準を提供し、ゲームコミュニティの拡大と健全な創作活動を奨励することを目的としています。
                </div>
                <div className="policy-section-title">1. 非営利目的のコンテンツ使用</div>
                <div className={descTextClass}>非営利目的での以下の活動は自由に行えます：</div>
                <li className={descTextClass}>ファンアート、イラスト、同人誌、小説 などの二次創作物の制作および共有</li>
                <li className={descTextClass}>プレイ動画、スクリーンショット を使用したレビューや紹介動画の制作および共有。
                </li>
                <li className={descTextClass}>SNSやブログ などでのゲーム関連の非営利投稿および共有。</li>
                <div className="policy-section-title">2. 営利目的のコンテンツ使用</div>
                <div
                    className={descTextClass}>ゲームを利用して収益を得るコンテンツ制作も許可されています。以下の条件を満たす必要があります：
                </div>
                <li className={descTextClass}>YouTubeやTwitch などのプラットフォームで 広告収益
                    を得るストリーミングや動画制作を許可。
                </li>
                <li className={descTextClass}>ファンからの寄付やサブスク を通じたインフルエンサー活動を許可。</li>
                <li className={descTextClass}>ただし、ゲーム会社の許可なしに、AXTORMや「MASH VP!
                    Re:VISION」と公式に協力またはスポンサーシップを受けているように見せかける行為 は禁止されています。
                </li>
                <div className="policy-section-title">3. 禁止事項</div>
                <div className={descTextClass}>以下のコンテンツは一切禁止されます：</div>
                <li className={descTextClass}>ゲームの 無断改変 や 非公式サーバー に関連するコンテンツの制作。</li>
                <li className={descTextClass}>政治的、宗教的、暴力的、または差別的なメッセージを含むコンテンツ。</li>
                <li className={descTextClass}>他者の著作権を侵害する行為、他者の権利を侵害する行為。</li>
                <li className={descTextClass}>ゲームの ストーリーや音楽など主要要素に関するネタバレ
                    を含む投稿。ただし、公式に公開された内容に関するレビューは許可されます。
                </li>
                <div className="policy-section-title">4. プレイ動画の商用利用に関する特例</div>
                <li className={descTextClass}>YouTube、Twitch
                    などのパートナープログラムを通じて広告収益を得る場合、営利目的のプレイ動画の制作・投稿は許可されます。ただし、他者の権利を侵害しないこと、また当社のゲームの名誉を損なわない内容であることが前提です。
                </li>
                <div className="policy-section-title">5. 使用に際しての注意事項</div>
                <li className={descTextClass}>本ガイドラインに違反する行為が確認された場合、当社は該当コンテンツの削除を要請する権利を有します。</li>
                <li className={descTextClass}>ガイドラインは予告なしに変更される場合があり、変更された場合は直ちにホームページにて告知されます。</li>
                <div className="policy-section-title">6. 著作権およびその他の問い合わせ</div>
                <div className={descTextClass}>コンテンツ使用に関してご質問がある場合は、support@axtormjapan.com
                    までお問い合わせください。
                </div>
            </div>,
            "kr": <div className="policy-section">
                <div className="policy-title">콘텐츠 사용에 관한 가이드라인</div>
                <div className={descTextClass}>AXTORM이 개발한 리듬 게임 「MASH VP! Re:VISION」의 콘텐츠를 사용하여 2차 창작 활동을 하는 여러분을 환영합니다. 이 가이드라인은 게임 콘텐츠를 영리 및 비영리 목적으로 사용할 때의 기준을 제공하며, 게임 커뮤니티의 확장과 건전한 창작 활동을 장려하기 위한 목적을 가지고 있습니다.</div>
                <div className="policy-section-title">1. 비영리 목적의 콘텐츠 사용</div>
                <div className={descTextClass}>비영리 목적으로 아래와 같은 활동을 자유롭게 할 수 있습니다:</div>
                <li className={descTextClass}>팬아트, 일러스트, 동인지, 소설 등의 2차 창작물 제작 및 공유</li>
                <li className={descTextClass}>플레이 영상, 스크린샷을 사용한 리뷰 및 소개 영상 제작 및 공유</li>
                <li className={descTextClass}>SNS나 블로그 등에서 게임 관련 비영리 게시물 및 공유</li>
                <div className="policy-section-title">2. 영리 목적의 콘텐츠 사용</div>
                <div
                    className={descTextClass}>게임을 이용하여 수익을 얻는 콘텐츠 제작도 허용됩니다. 다음 조건을 충족해야 합니다:</div>
                <li className={descTextClass}>YouTube, Twitch 등의 플랫폼에서 광고 수익을 얻는 스트리밍 및 영상 제작 허용</li>
                <li className={descTextClass}>팬으로부터의 기부나 구독을 통한 인플루언서 활동 허용</li>
                <li className={descTextClass}>단, 게임사의 허가 없이 AXTORM이나 「MASH VP! Re:VISION」과 공식 협력 또는 후원 관계인 것처럼 보이는 행위는 금지됩니다.</li>
                <div className="policy-section-title">3. 금지 사항</div>
                <div className={descTextClass}>다음과 같은 콘텐츠는 일절 금지됩니다:</div>
                <li className={descTextClass}>게임의 무단 개변 또는 비공식 서버와 관련된 콘텐츠 제작</li>
                <li className={descTextClass}>정치적, 종교적, 폭력적, 또는 차별적인 메시지를 포함하는 콘텐츠</li>
                <li className={descTextClass}>타인의 저작권을 침해하는 행위, 타인의 권리를 침해하는 행위</li>
                <li className={descTextClass}>게임의 스토리, 음악 등 주요 요소에 관한 스포일러가 포함된 게시물 (단, 공식적으로 공개된 내용에 대한 리뷰는 허용됩니다)</li>
                <div className="policy-section-title">4. 플레이 영상의 상업적 이용에 관한 특례</div>
                <li className={descTextClass}>YouTube, Twitch 등의 파트너 프로그램을 통해 광고 수익을 얻는 경우, 영리 목적의 플레이 영상 제작 및 게시가 허용됩니다. 단, 타인의 권리를 침해하지 않으며, 당사의 게임 명예를 손상시키지 않는 내용이어야 합니다.</li>
                <div className="policy-section-title">5. 사용 시 주의사항</div>
                <li className={descTextClass}>본 가이드라인을 위반하는 행위가 확인될 경우, 당사는 해당 콘텐츠의 삭제를 요청할 권리를 보유합니다.</li>
                <li className={descTextClass}>본 가이드라인은 사전 예고 없이 변경될 수 있으며, 변경 시 즉시 홈페이지에 공지됩니다.</li>
                <div className="policy-section-title">6. 저작권 및 기타 문의</div>
                <div className={descTextClass}>콘텐츠 사용에 관한 문의사항이 있으시면 support@axtormjapan.com 으로 연락해주시기 바랍니다.</div>
            </div>
        }
    }
    const divDatabaseMobile = {
        contentsPolicy:{
            "en": <div className="policy-section-mobile">
                <div className="policy-title-mobile">Contents Usage Guidelines</div>
                <div className="policy-desc-text-mobile">AXTORM welcomes everyone who wants to make their own content using ideas
                    and content from MASH VP! Re:VISION! The purpose of these guidelines is to provide standards for the
                    use of game content for commercial and non-commercial purposes, while also allowing us to expand the
                    MASH VP! community and encourage the creativity of those in our community.
                </div>
                <div className="policy-section-title-mobile">1. Non-Commercial Use of Content</div>
                <div className="policy-desc-text-mobile">You are free to engage in the following activities for non-commercial
                    purposes：
                </div>
                <li className="policy-desc-text-mobile">The creation and sharing of fan art, illustrations, novels, and other
                    fan-created content
                </li>
                <li className="policy-desc-text-mobile">The creation of videos or reviews containing in game screenshots and other
                    in-game content
                </li>
                <li className="policy-desc-text-mobile">Posting and sharing game-related content on social networking sites and
                    blogs for non-commercial purposes
                </li>
                <div className="policy-section-title-mobile">2. Commercial Use of Content</div>
                <div
                    className="policy-desc-text-mobile">Content creation using the game that generates revenue is also permitted
                    in the following cases:
                </div>
                <li className="policy-desc-text-mobile">Streaming and video production for advertising revenue on platforms such
                    as YouTube and Twitch
                </li>
                <li className="policy-desc-text-mobile">Earning revenue through fan donations and subscriptions is allowed.</li>
                <li className="policy-desc-text-mobile">Other cases may be allowed, but please contact AXTORM prior for
                    approval.
                </li>
                <div className="policy-section-title-mobile">3. Prohibited Uses</div>
                <div className="policy-desc-text-mobile">The following actions are strictly prohibited:</div>
                <li className="policy-desc-text-mobile">Unauthorized modification of the game or creation of content related to or
                    using unofficial servers to play the game.
                </li>
                <li className="policy-desc-text-mobile">Content that contains political, religious, violent, or discriminatory
                    messages.
                </li>
                <li className="policy-desc-text-mobile">Any activity that infringes on copyrights held by other parties, or
                    violates the rights of others.
                </li>
                <li className="policy-desc-text-mobile">Posts that contain spoilers about key elements of the game, such as
                    storyline, music, or any unreleased content.
                </li>
                <div className="policy-section-title-mobile">4. Special Provisions for the Commercial Use of Gameplay Videos</div>
                <li className="policy-desc-text-mobile">YouTube、Twitch
                    If you wish to generate advertising revenue through partner programs such as YouTube, Twitch, or other similar platforms, you are permitted to produce and post videos of your gameplay for commercial purposes. However, the content posted must not infringe on any of the points listed in Section 3.</li>
                <div className="policy-section-title-mobile">5. Content Usage Precautions</div>
                <li className="policy-desc-text-mobile">If any violation of these guidelines is discovered, we reserve the right to request that the content in question be removed.</li>
                <li className="policy-desc-text-mobile">The guidelines are subject to change without notice, and any changes will be immediately posted to this site.</li>
                <div className="policy-section-title-mobile">6. Questions Regarding Content Usage</div>
                <div className="policy-desc-text-mobile">If you have any questions about these guidelines, or have questions about a specific use case you have in mind, contact us at support@axtormjapan.com.</div>
                <div className="policy-desc-text-mobile">We have native English speakers on staff, so feel free to email in English.</div>
            </div>,
            "jp": <div className="policy-section-mobile">
                <div className="policy-title-mobile">コンテンツ使用に関するガイドライン</div>
                <div className="policy-desc-text-mobile">AXTORMが開発したリズムゲーム 「MASH VP! Re:VISION」
                    のコンテンツを使用して二次創作活動を行う皆様を歓迎します。このガイドラインは、営利目的および非営利目的でゲームコンテンツを使用する際の基準を提供し、ゲームコミュニティの拡大と健全な創作活動を奨励することを目的としています。
                </div>
                <div className="policy-section-title-mobile">1. 非営利目的のコンテンツ使用</div>
                <div className="policy-desc-text-mobile">非営利目的での以下の活動は自由に行えます：</div>
                <li className="policy-desc-text-mobile">ファンアート、イラスト、同人誌、小説 などの二次創作物の制作および共有</li>
                <li className="policy-desc-text-mobile">プレイ動画、スクリーンショット を使用したレビューや紹介動画の制作および共有。
                </li>
                <li className="policy-desc-text-mobile">SNSやブログ などでのゲーム関連の非営利投稿および共有。</li>
                <div className="policy-section-title-mobile">2. 営利目的のコンテンツ使用</div>
                <div
                    className="policy-desc-text-mobile">ゲームを利用して収益を得るコンテンツ制作も許可されています。以下の条件を満たす必要があります：
                </div>
                <li className="policy-desc-text-mobile">YouTubeやTwitch などのプラットフォームで 広告収益
                    を得るストリーミングや動画制作を許可。
                </li>
                <li className="policy-desc-text-mobile">ファンからの寄付やサブスク を通じたインフルエンサー活動を許可。</li>
                <li className="policy-desc-text-mobile">ただし、ゲーム会社の許可なしに、AXTORMや「MASH VP!
                    Re:VISION」と公式に協力またはスポンサーシップを受けているように見せかける行為 は禁止されています。
                </li>
                <div className="policy-section-title-mobile">3. 禁止事項</div>
                <div className="policy-desc-text-mobile">以下のコンテンツは一切禁止されます：</div>
                <li className="policy-desc-text-mobile">ゲームの 無断改変 や 非公式サーバー に関連するコンテンツの制作。</li>
                <li className="policy-desc-text-mobile">政治的、宗教的、暴力的、または差別的なメッセージを含むコンテンツ。</li>
                <li className="policy-desc-text-mobile">他者の著作権を侵害する行為、他者の権利を侵害する行為。</li>
                <li className="policy-desc-text-mobile">ゲームの ストーリーや音楽など主要要素に関するネタバレ
                    を含む投稿。ただし、公式に公開された内容に関するレビューは許可されます。
                </li>
                <div className="policy-section-title-mobile">4. プレイ動画の商用利用に関する特例</div>
                <li className="policy-desc-text-mobile">YouTube、Twitch
                    などのパートナープログラムを通じて広告収益を得る場合、営利目的のプレイ動画の制作・投稿は許可されます。ただし、他者の権利を侵害しないこと、また当社のゲームの名誉を損なわない内容であることが前提です。
                </li>
                <div className="policy-section-title-mobile">5. 使用に際しての注意事項</div>
                <li className="policy-desc-text-mobile">本ガイドラインに違反する行為が確認された場合、当社は該当コンテンツの削除を要請する権利を有します。</li>
                <li className="policy-desc-text-mobile">ガイドラインは予告なしに変更される場合があり、変更された場合は直ちにホームページにて告知されます。</li>
                <div className="policy-section-title-mobile">6. 著作権およびその他の問い合わせ</div>
                <div className="policy-desc-text-mobile">コンテンツ使用に関してご質問がある場合は、support@axtormjapan.com
                    までお問い合わせください。
                </div>
            </div>,
            "kr": <div className="policy-section-mobile">
                <div className="policy-title-mobile">콘텐츠 사용에 관한 가이드라인</div>
                <div className="policy-desc-text-mobile">AXTORM이 개발한 리듬 게임 「MASH VP! Re:VISION」의 콘텐츠를 사용하여 2차 창작 활동을 하는 여러분을 환영합니다. 이 가이드라인은 게임 콘텐츠를 영리 및 비영리 목적으로 사용할 때의 기준을 제공하며, 게임 커뮤니티의 확장과 건전한 창작 활동을 장려하기 위한 목적을 가지고 있습니다.</div>
                <div className="policy-section-title-mobile">1. 비영리 목적의 콘텐츠 사용</div>
                <div className="policy-desc-text-mobile">비영리 목적으로 아래와 같은 활동을 자유롭게 할 수 있습니다:</div>
                <li className="policy-desc-text-mobile">팬아트, 일러스트, 동인지, 소설 등의 2차 창작물 제작 및 공유</li>
                <li className="policy-desc-text-mobile">플레이 영상, 스크린샷을 사용한 리뷰 및 소개 영상 제작 및 공유</li>
                <li className="policy-desc-text-mobile">SNS나 블로그 등에서 게임 관련 비영리 게시물 및 공유</li>
                <div className="policy-section-title-mobile">2. 영리 목적의 콘텐츠 사용</div>
                <div
                    className="policy-desc-text-mobile">게임을 이용하여 수익을 얻는 콘텐츠 제작도 허용됩니다. 다음 조건을 충족해야 합니다:</div>
                <li className="policy-desc-text-mobile">YouTube, Twitch 등의 플랫폼에서 광고 수익을 얻는 스트리밍 및 영상 제작 허용</li>
                <li className="policy-desc-text-mobile">팬으로부터의 기부나 구독을 통한 인플루언서 활동 허용</li>
                <li className="policy-desc-text-mobile">단, 게임사의 허가 없이 AXTORM이나 「MASH VP! Re:VISION」과 공식 협력 또는 후원 관계인 것처럼 보이는 행위는 금지됩니다.</li>
                <div className="policy-section-title-mobile">3. 금지 사항</div>
                <div className="policy-desc-text-mobile">다음과 같은 콘텐츠는 일절 금지됩니다:</div>
                <li className="policy-desc-text-mobile">게임의 무단 개변 또는 비공식 서버와 관련된 콘텐츠 제작</li>
                <li className="policy-desc-text-mobile">정치적, 종교적, 폭력적, 또는 차별적인 메시지를 포함하는 콘텐츠</li>
                <li className="policy-desc-text-mobile">타인의 저작권을 침해하는 행위, 타인의 권리를 침해하는 행위</li>
                <li className="policy-desc-text-mobile">게임의 스토리, 음악 등 주요 요소에 관한 스포일러가 포함된 게시물 (단, 공식적으로 공개된 내용에 대한 리뷰는 허용됩니다)</li>
                <div className="policy-section-title-mobile">4. 플레이 영상의 상업적 이용에 관한 특례</div>
                <li className="policy-desc-text-mobile">YouTube, Twitch 등의 파트너 프로그램을 통해 광고 수익을 얻는 경우, 영리 목적의 플레이 영상 제작 및 게시가 허용됩니다. 단, 타인의 권리를 침해하지 않으며, 당사의 게임 명예를 손상시키지 않는 내용이어야 합니다.</li>
                <div className="policy-section-title-mobile">5. 사용 시 주의사항</div>
                <li className="policy-desc-text-mobile">본 가이드라인을 위반하는 행위가 확인될 경우, 당사는 해당 콘텐츠의 삭제를 요청할 권리를 보유합니다.</li>
                <li className="policy-desc-text-mobile">본 가이드라인은 사전 예고 없이 변경될 수 있으며, 변경 시 즉시 홈페이지에 공지됩니다.</li>
                <div className="policy-section-title-mobile">6. 저작권 및 기타 문의</div>
                <div className="policy-desc-text-mobile">콘텐츠 사용에 관한 문의사항이 있으시면 support@axtormjapan.com 으로 연락해주시기 바랍니다.</div>
            </div>
        }
    }

    function handleTextClasses() {
        setCurrentWidth(window.innerWidth)
        if (window.innerWidth > 1600) {
            setDescTextClass("policy-desc-text")
        } else if (window.innerWidth > 1300) {
            setDescTextClass("policy-desc-text-bp1")
        } else if (window.innerWidth > 1100) {
            setDescTextClass("policy-desc-text-bp2")
        }
    }

    return (
        currentWidth > 1100 ?
            <div className="policies-page">
                <Header logoOverride="" focusedArea="policies" isMobileAndNotMain={false} locale={locale}
                        setLocale={setLocale} videoEnded={true}/>
                {divDatabase.contentsPolicy[locale]}
                <Footer locale={locale}/>
            </div>
            :
            <div className="policies-page">
                <HeaderMobile logoOverride="" focusedArea="policies" isMobileAndNotMain={false} locale={locale}
                              setLocale={setLocale} videoEnded={true}/>
                {divDatabaseMobile.contentsPolicy[locale]}
            <FooterMobile locale={locale} />
        </div>
    )
}