import React, { useEffect, useState } from "react";
import "../Stylesheets/LanguageSelector.css"

export default function LanguageSelectorMobile(props: LanguageSelectorProps) {

    const locale = props.locale;
    const setLocale = props.setLocale;

    function handleLocaleChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const target = e.target as HTMLElement;
        const selectedLocale = target.textContent
        switch (selectedLocale) {
            case "ENGLISH":
                setLocale("en");
                break;
            case "JAPANESE":
                setLocale("jp");
                break;
            case "KOREAN": 
                setLocale("kr");
                break;
        }
    }

    return (
        <div className="language-menu-mobile">
            <div onClick={handleLocaleChange}
                className={locale === "en" ? "mobile-language activated-language" : "mobile-language"}>ENGLISH</div>
            <div onClick={handleLocaleChange}
                className={locale === "jp" ? "mobile-language activated-language" : "mobile-language"}>JAPANESE</div>
            <div onClick={handleLocaleChange}
                className={locale === "kr" ? "mobile-language activated-language" : "mobile-language"}>KOREAN</div>
        </div>
    )
}