import React, { useEffect, useRef } from "react";
import { useState } from "react";
import mainLogo from "../img/Logo_AXTORM-red.png"
import musicLogo from "../img/Logo-AXTORM_02.png"
import gamesLogo from "../img/Logo-AXTORM_03.png"
import "../StylesheetsMobile/HeaderMobile.css"
import MobileMenu from "./MobileMenu"
import LanguageSelector from "../Components/LanguageSelector";
import { Link } from "react-router-dom";

export default function HeaderMobile(props: HeaderProps) {

    const videoEnded = props.videoEnded;
    const isMobileAndNotMain = props.isMobileAndNotMain
    const currentPosition = props.currentPosition
    const focusedArea = props.focusedArea
    const windowHeight = document.documentElement.clientHeight
    const scrollHeight = document.documentElement.scrollHeight - windowHeight
    const logoOverride = props.logoOverride;


    const locale = props.locale
    const setLocale = props.setLocale


    const [mainLogoContainerClass, setMainLogoContainerClass] = useState<string>("")
    const [subLogoContainerClass, setSubLogoContainerClass] = useState<string>("")

    const mainLogoRef = useRef<HTMLAnchorElement>(null)
    const musicLogoRef = useRef<HTMLAnchorElement>(null)
    const gamesLogoRef = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
            mainLogoRef.current.style.opacity = "1"
            musicLogoRef.current.style.opacity = "0"
            gamesLogoRef.current.style.opacity = "0"
        }
        overrideLogo(logoOverride)
        handleResize();
    }, [])

    useEffect(() => {
        overrideLogo(logoOverride)
    }, [currentPosition])

    useEffect(() => {

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [window.innerWidth])

    function handleLogo() {
        if (currentPosition) {
            if (currentPosition / scrollHeight > 0.9) {
                //games logo active
                if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                    mainLogoRef.current.style.opacity = "0"
                    musicLogoRef.current.style.opacity = "0"
                    gamesLogoRef.current.style.opacity = "1"
                }
                return;
            }
            if (currentPosition / scrollHeight > 0.5) {
                //music logo active
                if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                    mainLogoRef.current.style.opacity = "0"
                    musicLogoRef.current.style.opacity = "1"
                    gamesLogoRef.current.style.opacity = "0"
                }
                return;
            }
            else {
                //main logo active
                if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                    mainLogoRef.current.style.opacity = "1"
                    musicLogoRef.current.style.opacity = "0"
                    gamesLogoRef.current.style.opacity = "0"
                }
            }
        }
    }

    function overrideLogo(logo: string) {
        if (logo === "") {
            handleLogo()
        }
        if (logo === "games") {
            if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                mainLogoRef.current.style.opacity = "0"
                musicLogoRef.current.style.opacity = "0"
                gamesLogoRef.current.style.opacity = "1"
            }
        }
        if (logo === "main") {
            if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                mainLogoRef.current.style.opacity = "1"
                musicLogoRef.current.style.opacity = "0"
                gamesLogoRef.current.style.opacity = "0"
            }
        }
    }

    function handleResize() {
        if (window.innerWidth > 500) {
            setMainLogoContainerClass("logo-container-mobile")
            setSubLogoContainerClass("sub-logo-container-mobile")
        }
        else {
            setMainLogoContainerClass("logo-container-mobile-bp1")
            setSubLogoContainerClass("sub-logo-container-mobile-bp1")
        }
    }


    return (
        videoEnded &&
        <div className={!isMobileAndNotMain ? "header-mobile" : "header-mobile-nonmain"}>
            <Link to={"/" + "?locale=" + locale} className={mainLogoContainerClass} ref={mainLogoRef}>
                <img className="main-logo-mobile" src={mainLogo}></img>
            </Link>
            <Link to={"/" + "?locale=" + locale} className={subLogoContainerClass} ref={musicLogoRef}>
                <img className="main-logo-mobile" src={musicLogo}></img>
            </Link>
            <Link to={"/" + "?locale=" + locale} className={subLogoContainerClass} ref={gamesLogoRef}>
                <img className="main-logo-mobile" src={gamesLogo}></img>
            </Link>
            <MobileMenu focusedArea={focusedArea} locale={locale} setLocale={setLocale} />
        </div>
    )

}