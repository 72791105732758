import React, { useEffect, useRef, useState } from "react";
import "../Stylesheets/LandingSection.css"
import "../fonts.css"
import {useSearchParams} from "react-router-dom";

export default function LandingSection(props: LandingSectionProps) {

    const locale = props.locale

    const videoEnded = props.videoEnded;
    const setVideoEnded = props.setVideoEnded;
    const currentPosition = props.currentPosition;
    const skipVideo = props.skipVideo

    const [landingMainTextClass, setLandingMainTextClass] =
        useState<string>("")

    const [landingMainTextContainerClass, setLandingMainTextContainerClass] =
        useState<string>("")

    const [landingSubTextClass, setLandingSubTextClass] =
        useState<string>("")

    const objectRef = useRef<HTMLDivElement>(null)
    const landingRef = useRef<HTMLDivElement>(null)
    const scrollDownRef = useRef<HTMLDivElement>(null)

    const divDatabase = {
        landingTextDiv: {
            "en":
                <div className="landing-subtext-container">
                    <div className={landingSubTextClass}>with the power of MUSIC and</div>
                    <div className={landingSubTextClass}>ENTERTAINMENT from JAPAN to the WORLD</div>
                </div>,
            "jp":
                <div className="landing-subtext-container">
                    <div className={landingSubTextClass}>音楽とゲームの力で、</div>
                    <div className={landingSubTextClass}>世界中に最高のエクスペリエンスをお届けします</div>
                </div>,
            "kr":
                <div className="landing-subtext-container">
                    <div className={landingSubTextClass}>음악과 게임의 힘으로</div>
                    <div className={landingSubTextClass}>전 세계에 최고의 엔터테이먼트를 선사합니다.</div>
                </div>
        }
    }

    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [currentPosition])

    useEffect(() => {

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }

    }, [window.innerWidth])

    function handleResize() {
        handleLandingMainText()
        handleLandingSubText()
    }

    function handleLandingMainText() {
        if (window.innerWidth > 1921) {
            setLandingMainTextClass("landing-maintext-big")
            setLandingMainTextContainerClass("landing-maintext-container")
        }
        else if (window.innerWidth > 1350) {
            setLandingMainTextClass("landing-maintext")
            setLandingMainTextContainerClass("landing-maintext-container")
        }
        else if (window.innerWidth > 1050) {
            setLandingMainTextClass("landing-maintext-bp1")
            setLandingMainTextContainerClass("landing-maintext-container-bp1")
        }
        else if (window.innerWidth > 700) {
            setLandingMainTextClass("landing-maintext-bp2")
            setLandingMainTextContainerClass("landing-maintext-container-bp2")
        }
        else {
            setLandingMainTextClass("landing-maintext-bp3")
            setLandingMainTextContainerClass("landing-maintext-container-bp3")
        }
    }

    function handleLandingSubText() {
        if (window.innerWidth > 1230) {
            setLandingSubTextClass("landing-subtext")
        }
        else if (window.innerWidth > 780) {
            setLandingSubTextClass("landing-subtext-bp1")
        }
        else {
            setLandingSubTextClass("landing-subtext-bp2")
        }
    }

    function handleVideoEnd() {
        setVideoEnded(true);
    }

    function handleScroll() {
        if (objectRef.current) {
            const factor = window.scrollY / 1500

            objectRef.current.style.borderTopLeftRadius = `${factor * 850}px`
            objectRef.current.style.borderTopRightRadius = `${factor * 850}px`
        }
        if (landingRef.current) {
            if ((window.outerHeight - currentPosition) + 300 < 0) {
                landingRef.current.style.opacity = "0";
            }
            else landingRef.current.style.opacity = "1";
        }
        if (scrollDownRef.current) {
            const factor = window.scrollY / window.innerHeight + 0.1
            scrollDownRef.current.style.opacity = (1 - factor).toString()
        }
    }
    return (
        <div className="landing-section" ref={landingRef}>
            {
                videoEnded &&
                <div className="main-container">
                    <div className="bg-object" ref={objectRef}></div>
                    <div className={landingMainTextContainerClass}>
                        <div className={landingMainTextClass}>REAL Excitement</div>
                        <div className={landingMainTextClass}>REAL Happiness</div>
                    </div>
                   {divDatabase.landingTextDiv[locale]}
                    <div className="scroll-down" ref={scrollDownRef}>SCROLL DOWN</div>
                </div>
            }
            {!skipVideo && <div className="video-container">
                <video className={videoEnded ? "intro-video-ended" : "intro-video"}
                       onEnded={handleVideoEnd}
                       autoPlay
                       muted
                       preload="auto">
                    <source
                        src="https://video.wixstatic.com/video/55d5f4_11d29831be5d40019e120e85cc9e002c/1080p/mp4/file.mp4"
                        type="video/mp4"></source>
                </video>
            </div>
                }

        </div>


    )
}