import React, { useEffect, useState } from "react";
import "../Stylesheets/LanguageSelector.css"

export default function LanguageSelector(props: LanguageSelectorProps) {

    const locale = props.locale;
    const setLocale = props.setLocale;

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [displayedLocale, setDisplayedLocale] = useState<string>("en")
    const [unsetLocales, setUnsetLocales] = useState<Array<string>>(["ENGLISH", "JAPANESE", "KOREAN"])


    useEffect(() => {
        populateLocales()
    }, [locale])

    useEffect(() => {
        const currentLocale = locale;
        populateLocales()
        if (currentLocale) {
            handleDisplayedLocale(currentLocale.toUpperCase())
        }
    }, [])

    function handleDisplayedLocale(locale: string) {
        switch (locale)
        {
            case "EN":
                setDisplayedLocale("ENGLISH")
                break;
            case "JP":
                setDisplayedLocale("JAPANESE")
                break;
            case "KR":
                setDisplayedLocale("KOREAN")
                break;
        }
    }

    function populateLocales() {
        let currentLocales = ["ENGLISH", "JAPANESE", "KOREAN"]
        let actualLocale = ""
        switch (locale) {
            case "en":
                actualLocale += "ENGLISH"
                break;
            case "jp":
                actualLocale += "JAPANESE"
                break;
            case "kr":
                actualLocale += "KOREAN"
                break;
        }
        for (let i = 0; i < currentLocales.length; i++) {
            if (currentLocales[i] === actualLocale) {
                currentLocales.splice(i, 1)
                setUnsetLocales(currentLocales)
            }
        }
    }

    function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        setIsOpen(!isOpen)
    }

    function handleLocaleChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const target = e.target as HTMLElement;
        const selectedLocale = target.textContent
        if (selectedLocale) {
            handleDisplayedLocale(selectedLocale)
            switch (selectedLocale) {
                case "ENGLISH":
                    setLocale("en");
                    setDisplayedLocale("ENGLISH");
                    break;
                case "JAPANESE":
                    setLocale("jp");
                    setDisplayedLocale("JAPANESE");
                    break;
                case "KOREAN":
                    setLocale("kr");
                    setDisplayedLocale("KOREAN");
                    break;
            }
            setIsOpen(false)
        }
    }

    return (
        <div className="language-menu-container">
            {!isOpen ?
                <div className="language-menu" onClick={e => handleClick(e)}>
                    <div className="language-icon"></div>
                    <div className="active-language">
                        {displayedLocale}
                    </div>
                </div> :
                <div className="full-language-menu">
                    <div className="language-menu-on" onClick={e => handleClick(e)}>
                        <div className="active-language">
                            {displayedLocale}
                        </div>
                    </div>
                    <div className="languages-dropdown">
                        {unsetLocales.map(locale => {
                            if (unsetLocales.indexOf(locale) !== unsetLocales.length - 1) {
                                return <div className="selectable-language" onClick={e => handleLocaleChange(e)}>{locale.toUpperCase()}</div>
                            }
                            else return <div className="selectable-language menu-end" onClick={e => handleLocaleChange(e)}>{locale.toUpperCase()}</div>
                        })}
                    </div>
                </div>}
        </div>
    )
}