import React, { useEffect, useState } from "react";
import Header from "./Header";
import NewsItemContainer from "./NewsItemContainer";
import "../Stylesheets/NewsSection.css"
import { items } from "@wix/data"
import { createClient, OAuthStrategy } from "@wix/sdk-react";
import { useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import HeaderMobile from "../ComponentsMobile/HeaderMobile";
import FooterMobile from "../ComponentsMobile/FooterMobile";

export default function NewsPage() {

    const [searchParams, setSearchParams] = useSearchParams()
    const [locale, setLocale] = useState<Locale>(stringToLocale(searchParams.get("locale") || "en"))


    const [currentWidth, setCurrentWidth] = useState<number>(0)

    const [wixData, setWixData] = useState<Array<NewsPost>>([])
    const wixApiKey = process.env.REACT_APP_WIX_CLIENT_ID;

    useEffect(() => {
        fetchData()
        handleResize()
    }, [])

    useEffect(() => {

        stringToLocale(checkLocale())
        fetchData()

    }, [locale])



    useEffect(() => {

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [window.innerWidth])

    function checkLocale(): string {
        const urlParam = searchParams.get("locale")
        const validParams = ["en", "jp", "kr"]
        if (urlParam) {
            if (validParams.includes(urlParam)) {
                setSearchParams({ locale: locale })
                return urlParam
            }
            else {
                setSearchParams({ locale: "en" })
                return "en"
            }
        }
        else {
            setSearchParams({ locale: "en" })
            return "en"
        }
    }

    function stringToLocale(stringLocale: string): Locale {
        switch (stringLocale) {
            case "en":
            case "jp":
            case "kr":
                return stringLocale
            default:
                return "en";
        }
    }

    function handleResize() {
        setCurrentWidth(window.innerWidth)
    }

    async function fetchData() {
        if (wixApiKey) {
            const wixClient = createClient({
                modules: { items },
                auth: OAuthStrategy({ clientId: wixApiKey })
            })
            let dataCollectionId = ""
            switch (locale)
            {
                case "en":
                    dataCollectionId += "NewsPosts-EN"
                    break;
                case "jp":
                    dataCollectionId += "NewsPosts-JP"
                    break;
                case "kr":
                    dataCollectionId += "NewsPosts-KR"
                    break;
            }
            const dataItemsList = await wixClient.items
                .queryDataItems({
                    dataCollectionId
                })
                .find()
            const newWixData: Array<NewsPost> = []
            dataItemsList.items.map(post => {
                if (post.data) {
                    if (post.data.title !== "") {
                        const newPost: NewsPost =
                        {
                            title: post.data.title,
                            date: post.data.date,
                            description: post.data.description,
                            image: post.data.image,
                            url: post.data.url,
                            urlDetail: post.data.urlDetail
                        }
                        newWixData.push(newPost)
                    }
                }
                else console.error("Bad data sent")
            })
            newWixData.sort((a, b) => 
            {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            })
            setWixData(newWixData)
        }
        else {
            console.error("api key is invalid")
        }
    }

    return (
        currentWidth > 1100 ?
            <div className="news-page">
                <Header logoOverride="main" focusedArea="news" isMobileAndNotMain={false} locale={locale} setLocale={setLocale} videoEnded={true} />
                <div className="news-section">
                    <div className="news-subsection">
                        <div className="news-section-title">NEWS</div>
                        <div className="news-items-box">
                            {wixData.map(post => {
                                return <NewsItemContainer locale={locale} currentWidth={currentWidth} post={post} />
                            })}
                        </div>

                    </div>
                </div>
                <Footer locale={locale} />
            </div>
            :
            <div className="news-page-mobile">
                <HeaderMobile logoOverride="main" focusedArea="news" isMobileAndNotMain={true} locale={locale} setLocale={setLocale} videoEnded={true} />
                <div className="news-section-mobile">
                    <div className="news-subsection-mobile">
                        <div className="news-section-title-mobile">NEWS</div>
                        <div className="news-items-box-mobile">
                            {wixData.map(post => {
                                return <NewsItemContainer locale={locale} currentWidth={currentWidth} post={post} />
                            })}
                        </div>
                    </div>
                </div>
                <FooterMobile locale={locale} />
            </div>
    )
}