import React, { forwardRef } from "react";
import "../Stylesheets/GamesSection.css"
import "../fonts.css"
import controllerImage from "../img/_Promotion_Turntable.png"
import catchCopyImage from "../img/hp-GAMES_CC.png"
import gameLogoImage from "../img/_Promotion_Typo_with_revision.png"
import gameLogoImageNew from "../img/_Promotion_Typo_with_revision-new.png"
import gamesBackgroundImage from "../img/hp-games_BG.png"

const GamesSection = forwardRef<HTMLDivElement, GamesSectionProps>((props, ref) => {

    const locale = props.locale

    const divDatabase = {
        gamesDescDiv: {
            "en":
                <div className="game-description-container">
                    <div className="game-description-text">A next-generation music game where 2 songs and 2 lanes meet!</div>
                    <div className="game-description-text">Featuring songs composed by well-known artists from various music games,</div>
                    <div className="game-description-text">dive into the new era of music gaming with MASH VP! Re:Vision!</div>
                </div>,
            "jp":
                <div className="game-description-container">
                    <div className="game-description-text jp-text-description">「MASH VP! Re:VISION」は、２つのルートと音楽が融合するMASH UPリズムゲーム！</div>
                    <div className="game-description-text jp-text-description">様々な音ゲーに携わってきた有名アーティスト楽曲はもちろん、</div>
                    <div className="game-description-text jp-text-description">楽曲の掛け合わせを楽しめる“マッシュアップ”で、音ゲーの新たな世界へ！</div>
                </div>,
            "kr":
                <div className="game-description-container">
                    <div className="game-description-text jp-text-description">[MASH VP! Re:VISION]은 두 개의 라인과 음악이 융합되는 MASH UP 리듬 게임입니다! 다양한 음악 게임에 참여해온 유명 아티스트들의 곡은 물론, 곡들을 결합해 즐길 수 있는 ‘매쉬업’ 모드를 통해 새로운 음악 게임 세계로 여러분을 초대합니다!</div>
                </div>
        }
    }



    return (
        <div className="games-section" ref={ref}>
            <div className="background-image">
                <img className="background-image-img" src={gamesBackgroundImage}></img>
            </div>
            <div className="controller-image-container">
                <img className="controller-image" src={controllerImage}></img>
            </div>
            <div className="games-section-title">OUT NOW!</div>
            <video className="video-iframe" loop autoPlay
                muted>
                <source src="https://video.wixstatic.com/video/55d5f4_c753e11fc3b946ccbfa269098839b719/1080p/mp4/file.mp4" type="video/mp4"></source>
            </video>
            <a href={"/mashup" + "?locale=" + locale} className="game-icon-container">
                <img className="game-icon-games-section" src={gameLogoImageNew}></img>
            </a>
            <a href={"/mashup" + "?locale=" + locale} className="click-here">▲ CLICK HERE FOR DETAILS ▲</a>
            <div className="game-catchcopy-container">
                <img src={catchCopyImage} className="game-catchcopy-img"></img>
            </div>
            {divDatabase.gamesDescDiv[locale]}
        </div>
    )

})

export default GamesSection