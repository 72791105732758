import React, { useEffect, useRef } from "react";
import { useState } from "react";
import mainLogo from "../img/Logo_AXTORM-red.png"
import musicLogo from "../img/Logo-AXTORM_02.png"
import gamesLogo from "../img/Logo-AXTORM_03.png"
import "../Stylesheets/Header.css"
import LanguageSelector from "./LanguageSelector";
import { Link } from "react-router-dom";


export default function Header(props: HeaderProps) {

    const videoEnded = props.videoEnded;
    const currentPosition = props.currentPosition
    const locale = props.locale
    const setLocale = props.setLocale
    const focusedArea = props.focusedArea;
    const logoOverride = props.logoOverride;

    const mainLogoRef = useRef<HTMLAnchorElement>(null)
    const musicLogoRef = useRef<HTMLAnchorElement>(null)
    const gamesLogoRef = useRef<HTMLAnchorElement>(null)


    useEffect(() => {
        if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
            mainLogoRef.current.style.opacity = "1"
            musicLogoRef.current.style.opacity = "0"
            gamesLogoRef.current.style.opacity = "0"
        }
        overrideLogo(logoOverride)
    }, [])

    useEffect(() => {
        overrideLogo(logoOverride)
    }, [currentPosition])


    function handleLogo() {
        if (currentPosition) {
            if (currentPosition / window.innerHeight > 6.1) {
                //games logo active
                if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                    mainLogoRef.current.style.opacity = "0"
                    musicLogoRef.current.style.opacity = "0"
                    gamesLogoRef.current.style.opacity = "1"
                }
                return;
            }
            if (currentPosition / window.innerHeight > 4) {
                //music logo active
                if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                    mainLogoRef.current.style.opacity = "0"
                    musicLogoRef.current.style.opacity = "1"
                    gamesLogoRef.current.style.opacity = "0"
                }
                return;
            }
            else {
                //main logo active
                if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                    mainLogoRef.current.style.opacity = "1"
                    musicLogoRef.current.style.opacity = "0"
                    gamesLogoRef.current.style.opacity = "0"
                }
            }
        }
    }

    function overrideLogo(logo: string) {
        if (logo === "") {
            handleLogo()
        }
        if (logo === "games") {
            if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                mainLogoRef.current.style.opacity = "0"
                musicLogoRef.current.style.opacity = "0"
                gamesLogoRef.current.style.opacity = "1"
            }
        }
        if (logo === "main") {
            if (mainLogoRef.current && musicLogoRef.current && gamesLogoRef.current) {
                mainLogoRef.current.style.opacity = "1"
                musicLogoRef.current.style.opacity = "0"
                gamesLogoRef.current.style.opacity = "0"
            }
        }
    }





return (
    videoEnded &&
    <div className="header-main">
        <Link className="logo-container" to={"/" + "?locale=" + locale + "#main"} ref={mainLogoRef}>
            <img className="main-logo" src={mainLogo}></img>
        </Link>
        <Link className="sub-logo-container" to={"/" + "?locale=" + locale + "#main"} ref={musicLogoRef}>
            <img className="main-logo" src={musicLogo}></img>
        </Link>
        <Link className="sub-logo-container" to={"/" + "?locale=" + locale + "#main"} ref={gamesLogoRef}>
            <img className="main-logo" src={gamesLogo}></img>
        </Link>

        <div className="header-menu">
            <Link to={"/" + "?locale=" + locale + "#aboutUs"} className="menu-item"><div>ABOUT US</div></Link>
            <Link to={"/" + "?locale=" + locale + "#music"} className="menu-item"><div>MUSIC</div></Link>
            <Link to={"/" + "?locale=" + locale + "#games"} className="menu-item"><div>GAMES</div></Link>
            <a className={focusedArea === "news" ? "menu-item" + " focused-area" : "menu-item"} href={"/news" + "?locale=" + locale}>
                <div>NEWS</div>
            </a>
            <a className={focusedArea === "recruit" ? "menu-item" + " focused-area" : "menu-item"} href={"/recruit" + "?locale=" + locale}>
                <div>RECRUIT</div>
            </a>
            <a className={focusedArea === "policies" ? "menu-item" + " focused-area" : "menu-item"}
                href={"/guidelines" + "?locale=" + locale}>
                <div>GUIDELINES</div>
            </a>
        </div>
        {focusedArea !== "recruit" && <LanguageSelector locale={locale} setLocale={setLocale} />}
    </div>
)

}