import react, { useEffect, useState } from "react"
import "../Stylesheets/MashupPage.css"
import "../fonts.css"
import Header from "./Header"
import { useSearchParams } from "react-router-dom"
import bgImage from "../img/hp_games-MV_BG.png"
import catchCopyImage from "../img/hp-GAMES_CC.png"
import gameLogoImage from "../img/_Promotion_Typo_with_revision.png"
import freeModeJpImage from "../img/hp-games_free_ti-jp.png"
import freeModeEnImage from "../img/hp-games_free_ti-en.png"
import freeModeKrImage from "../img/hp-games_free_ti-k.png"
import tutorialJpImage from "../img/hp-games_tutorial_ti-jp.png"
import tutorialEnImage from "../img/hp-games_tutorial_ti-en.png"
import tutorialKrImage from "../img/hp-games_tutorial_ti-k.png"
import mashupJpImage from "../img/hp-games_mashup_ti-jp.png"
import mashupEnImage from "../img/hp-games_mashup_ti-en.png"
import mashupKrImage from "../img/hp-games_mashup_ti-k.png"
import lanesImage from "../img/hp-games_free01.png"
import mashupBgImage from "../img/hp-games_mashup02.png"
import earlyAccessBgImage from "../img/modeitems_combined.png"
import tutorialExImageJp from "../img/hp-games_howtoplay01.png"
import tutorialExImageEn from "../img/hp-games_howtoplay_en.png"
import tutorialExImageKr from "../img/hp-games_howtoplay_k.png"
import characterIntroJp from "../img/hp_Character.png"
import characterIntroEn from "../img/hp_Character-en.png"
import characterIntroKr from "../img/hp_Character_k.png"
import steamLogo from "../img/steam-banner.png"
import HeaderMobile from "../ComponentsMobile/HeaderMobile"
import FooterMobile from "../ComponentsMobile/FooterMobile"
import Footer from "./Footer"


export default function MashupPage() {
    const [currentWidth, setCurrentWidth] = useState<number>(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [videoWidth, setVideoWidth] = useState<number>(1280)
    const [videoWidthMobile, setVideoWidthMobile] = useState<number>(1280)
    const [descTextClass, setDescTextClass] = useState<string>("")
    const [locale, setLocale] = useState<Locale>(stringToLocale(searchParams.get("locale") || "en"))
    const [rayPlaying, setRayPlaying] = useState<boolean>(false);

    const divDatabase = {
        mashupTitleTextDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">Experience a new world of “rhythm games” with MASH VP! Re:VISION!</div>
                    <div className="mashup-description-text">Release Date: 10/11/2024</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">今までにない初めての“音ゲー”の世界へ！</div>
                    <div className="mashup-description-text">2024年10月11日アーリーアクセス版発売中！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">MASH VP! Re:VISION과 함께 새로운 세계의 “리듬 게임”을 경험하세요!</div>
                    <div className="mashup-description-text">출시일: 2024년 10월 11일</div>
                </div>
        },
        steamLogoDiv: {
            "en":
                <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/" className="steam-logo-div">
                    <img src={steamLogo}></img>
                </a>,
            "jp":
                <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/?l=japanese" className="steam-logo-div">
                    <img src={steamLogo}></img>
                </a>,
            "kr":
                <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/?l=korean" className="steam-logo-div">
                    <img src={steamLogo}></img>
                </a>
        },
        freePlayImageDiv: {
            "en":
                <div className="mode-description-image">
                    <img src={freeModeEnImage}></img>
                </div>,
            "jp":
                <div className="mode-description-image">
                    <img src={freeModeJpImage}></img>
                </div>,
            "kr":
                <div className="mode-description-image">
                    <img src={freeModeKrImage}></img>
                </div>
        },
        freePlaySectionDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">In “LIGHT MIX” songs are played with notes flowing along 2 lanes,</div>
                    <div className="mashup-description-text">whereas “HARD MIX” has notes flowing along 4 lanes.</div>
                    <div className="mashup-description-text">With 5 levels of difficulty to choose from;</div>
                    <div className="mashup-description-text">Easy, Normal, Hard, Expert, and Master,</div>
                    <div className="mashup-description-text">you can feel the exhilaration as you challenge each new level!</div>
                    <div className="mashup-description-text">Enjoy playing according to your skill level and mood!</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">各2レーンでノートが流れてくる「LIGHT MIX」、</div>
                    <div className="mashup-description-text">各4レーンでノートが流れてくる「HARD MIX」の2つのプレースタイルを搭載。</div>
                    <div className="mashup-description-text">難易度はEasy、Normal、Hard、Expertの4段階あり、</div>
                    <div className="mashup-description-text">難しいレベルの楽曲に挑戦できるようになると爽快感UP!!</div>
                    <div className="mashup-description-text">あなたのスキルや、気分に合わせてプレイを楽しんで！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">“LIGHT MIX”에서는 두 개의 라인을 따라 노트가 흐르며,</div>
                    <div className="mashup-description-text">“HARD MIX”에서는 네 개의 라인을 따라 노트가 흐릅니다.</div>
                    <div className="mashup-description-text">난이도는 Easy, Normal, Hard, Expert, Master 다섯 가지 중 선택할 수 있어,</div>
                    <div className="mashup-description-text">각 단계에 도전할 때마다 짜릿한 즐거움을 느낄 수 있습니다!</div>
                    <div className="mashup-description-text">자신의 실력과 기분에 맞게 게임을 즐겨보세요!!</div>
                </div>
        },
        tutorialImageDiv: {
            "en":
                <div className="mode-description-image">
                    <img src={tutorialEnImage}></img>
                </div>,
            "jp":
                <div className="mode-description-image">
                    <img src={tutorialJpImage}></img>
                </div>,
            "kr":
                <div className="mode-description-image">
                    <img src={tutorialKrImage}></img>
                </div>
        },
        tutorialExampleDiv: {
            "en":
                <div className="tutorial-example-image">
                    <img src={tutorialExImageEn}></img>
                </div>,
            "jp":
                <div className="tutorial-example-image">
                    <img src={tutorialExImageJp}></img>
                </div>,
            "kr":
                <div className="tutorial-example-image">
                    <img src={tutorialExImageKr}></img>
                </div>
        },
        mashupImageDiv: {
            "en":
                <div className="mode-description-image">
                    <img src={mashupEnImage}></img>
                </div>,
            "jp":
                <div className="mode-description-image">
                    <img src={mashupJpImage}></img>
                </div>,
            "kr":
                <div className="mode-description-image">
                    <img src={mashupKrImage}></img>
                </div>
        },
        mashupSectionDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">A new experience where two songs become one!</div>
                    <div className="mashup-description-text">Mashup Mode is an innovative feature unique to "MASH VP! Re:VISION"</div>
                    <div className="mashup-description-text">that combines two songs to create a new experience altogether!</div>
                    <div className="mashup-description-text">By selecting songs in "FREE PLAY," you unlock exclusive "MASH UP" tracks!</div>
                    <div className="mashup-description-text">One new song where musical worlds collide!</div>
                    <div className="mashup-description-text">The charts and themes truly embody 'MASH VP!'</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">「FREE PLAY」で選曲した楽曲によって「MASH UP」限定の楽曲が解禁！</div>
                    <div className="mashup-description-text">一つの楽曲が、幾つもの世界を描き始める！譜面も世界観もまさに『MASH VP!』！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">프리 플레이 모드에서 곡을 선택하면, 매쉬업 전용 트랙이 잠금 해제됩니다!</div>
                    <div className="mashup-description-text">서로 다른 음악 세계가 충돌하여 탄생한 한 곡!</div>
                    <div className="mashup-description-text">이 차트와 테마는 'MASH VP!'의 진정한 매력을 보여줍니다.</div>
                </div>
        },
        characterIntroDiv: {
            "en":
                <div className="character-image">
                    <img src={characterIntroEn}></img>
                </div>,
            "jp":
                <div className="character-image">
                    <img src={characterIntroJp}></img>
                </div>,
            "kr":
                <div className="character-image">
                    <img src={characterIntroKr}></img>
                </div>,
        },
        earlyAccessSectionDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">"MASH VP! Re:VISION" Early Access includes the following game modes from day one:</div>
                    <div className="mashup-description-text">・FREE PLAY</div>
                    <div className="mashup-description-text">・MASH UP</div>
                    <div className="mashup-description-text">We will be adding more songs prior to the full version release,</div>
                    <div className="mashup-description-text">which will include over 90 tracks upon launch!</div>
                    <div className="mashup-description-text">This includes regular single tracks in “FREE PLAY” and special mashup tracks in “MASH UP!”</div>
                    <div className="mashup-description-text">Additionally, we will be collecting and incorporating player feedback from Early Access</div>
                    <div className="mashup-description-text">directly into the development of the full version.</div>
                    <div className="mashup-description-text">Multiple updates are planned during Early Access, and we intend to add new modes such as</div>
                    <div className="mashup-description-text">"GHOST BATTLE," where you can challenge other players' score replays, and</div>
                    <div className="mashup-description-text">"CHALLENGE," which allows you to test your skills!</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">「MASH VP! Re:VISION」はアーリーアクセスでは</div>
                    <div className="mashup-description-text">・FREE PLAY</div>
                    <div className="mashup-description-text">・MASH UP</div>
                    <div className="mashup-description-text">上記２つのモードをプレイいただけます！</div>
                    <div className="mashup-description-text">フルバージョンリリース時までに楽曲を追加し、</div>
                    <div className="mashup-description-text">フルバージョンリリース時には「FREE PLAY」の通常楽曲、</div>
                    <div className="mashup-description-text">「MASH UP」のマッシュアップ楽曲を合わせて、90曲以上がプレイ可能に！</div>
                    <div className="mashup-description-text">また、アーリーアクセスでは、プレイヤーの皆様からいただいたご意見を</div>
                    <div className="mashup-description-text">開発に反映させることも目的として公開しております。</div>
                    <div className="mashup-description-text">アップデートは複数回予定しており</div>
                    <div className="mashup-description-text">他プレイヤーのスコアに挑む「GHOST BATTLE」や</div>
                    <div className="mashup-description-text">自分の腕前を測る「CHALLENGE」といったモードを追加予定！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className="mashup-description-text">「MASH VP! Re:VISION」 얼리 액세스에서는</div>
                    <div className="mashup-description-text">・FREE PLAY</div>
                    <div className="mashup-description-text">・MASH UP</div>
                    <div className="mashup-description-text">이 두 가지 모드를 플레이하실 수 있습니다!</div>
                    <div className="mashup-description-text">정식 버전 출시 전까지 곡이 추가되며,</div>
                    <div className="mashup-description-text">정식 출시 시점에는 "FREE PLAY"의 일반 곡과 "MASH UP"의 매쉬업 곡을 포함하여 90곡 이상을 플레이할 수 있게 됩니다!</div>
                    <div className="mashup-description-text">또한, 얼리 액세스는 플레이어 여러분의 의견을 개발에 반영하는 것을 목표로 공개되었습니다.</div>
                    <div className="mashup-description-text">여러 차례 업데이트가 예정되어 있으며,</div>
                    <div className="mashup-description-text">다른 플레이어의 점수에 도전하는 "GHOST BATTLE" 모드나 자신의 실력을 테스트할 수 있는 "CHALLENGE" 모드가 추가될 예정입니다!</div>
                </div>
        }
    }

    const mobileDivDatabase = {
        mashupTitleTextDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className={descTextClass}>Experience a new world of “rhythm games” with MASH VP! Re:VISION!</div>
                    <div className={descTextClass}>Release Date: 10/11/2024</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className={descTextClass}>今までにない初めての“音ゲー”の世界へ！</div>
                    <div className={descTextClass}>2024年10月11日発売決定！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className={descTextClass}>MASH VP! Re:VISION과 함께 새로운 세계의 “리듬 게임”을 경험하세요!</div>
                    <div className={descTextClass}>출시일: 2024년 10월 11일</div>
                </div>

        },
        steamLogoDiv: {
            "en":
                <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/" className="steam-logo-div-mobile">
                    <img src={steamLogo}></img>
                </a>,
            "jp":
                <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/" className="steam-logo-div-mobile">
                    <img src={steamLogo}></img>
                </a>,
            "kr": 
                <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/" className="steam-logo-div-mobile">
                    <img src={steamLogo}></img>
                </a>
        },
        freePlayImageDiv: {
            "en":
                <div className="mode-description-image-mobile">
                    <img src={freeModeEnImage}></img>
                </div>,
            "jp":
                <div className="mode-description-image-mobile">
                    <img src={freeModeJpImage}></img>
                </div>,
            "kr":
                <div className="mode-description-image-mobile">
                    <img src={freeModeKrImage}></img>
                </div>
        },
        freePlaySectionDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className={descTextClass}>In “LIGHT MIX” songs are played with notes flowing along 2 lanes,</div>
                    <div className={descTextClass}>whereas “HARD MIX” has notes flowing along 4 lanes.</div>
                    <div className={descTextClass}>With 5 levels of difficulty to choose from;</div>
                    <div className={descTextClass}>Easy, Normal, Hard, Expert, and Master,</div>
                    <div className={descTextClass}>you can feel the exhilaration as you challenge each new level!</div>
                    <div className={descTextClass}>Enjoy playing according to your skill level and mood!</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className={descTextClass}>各2レーンでノートが流れてくる「LIGHT MIX」、</div>
                    <div className={descTextClass}>各4レーンでノートが流れてくる「HARD MIX」の2つのプレースタイルを搭載。</div>
                    <div className={descTextClass}>難易度はEasy、Normal、Hard、Expertの4段階あり、</div>
                    <div className={descTextClass}>難しいレベルの楽曲に挑戦できるようになると爽快感UP!!</div>
                    <div className={descTextClass}>あなたのスキルや、気分に合わせてプレイを楽しんで！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className={descTextClass}>“LIGHT MIX”에서는 두 개의 라인을 따라 노트가 흐르며,</div>
                    <div className={descTextClass}>“HARD MIX”에서는 네 개의 라인을 따라 노트가 흐릅니다.</div>
                    <div className={descTextClass}>난이도는 Easy, Normal, Hard, Expert, Master 다섯 가지 중 선택할 수 있어,</div>
                    <div className={descTextClass}>각 단계에 도전할 때마다 짜릿한 즐거움을 느낄 수 있습니다!</div>
                    <div className={descTextClass}>자신의 실력과 기분에 맞게 게임을 즐겨보세요!!</div>
                </div>
        },
        tutorialImageDiv: {
            "en":
                <div className="mode-description-image-mobile">
                    <img src={tutorialEnImage}></img>
                </div>,
            "jp":
                <div className="mode-description-image-mobile">
                    <img src={tutorialJpImage}></img>
                </div>,
            "kr":
                <div className="mode-description-image-mobile">
                    <img src={tutorialKrImage}></img>
                </div>,
        },
        tutorialExampleDiv: {
            "en":
                <div className="tutorial-example-image-mobile">
                    <img src={tutorialExImageEn}></img>
                </div>,
            "jp":
                <div className="tutorial-example-image-mobile">
                    <img src={tutorialExImageJp}></img>
                </div>,
            "kr":
                <div className="tutorial-example-image-mobile">
                    <img src={tutorialExImageJp}></img>
                </div>
        },
        mashupImageDiv: {
            "en":
                <div className="mode-description-image-mobile">
                    <img src={mashupEnImage}></img>
                </div>,
            "jp":
                <div className="mode-description-image-mobile">
                    <img src={mashupJpImage}></img>
                </div>,
            "kr":
                <div className="mode-description-image-mobile">
                    <img src={mashupKrImage}></img>
                </div>
        },
        mashupSectionDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className={descTextClass}>A new experience where two songs become one!</div>
                    <div className={descTextClass}>Mashup Mode is an innovative feature unique to "MASH VP! Re:VISION"</div>
                    <div className={descTextClass}>that combines two songs to create a new experience altogether!</div>
                    <div className={descTextClass}>By selecting songs in "FREE PLAY," you unlock exclusive "MASH UP" tracks!</div>
                    <div className={descTextClass}>One new song where musical worlds collide!</div>
                    <div className={descTextClass}>The charts and themes truly embody 'MASH VP!'</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className={descTextClass}>「FREE PLAY」で選曲した楽曲によって「MASH UP」限定の楽曲が解禁！</div>
                    <div className={descTextClass}>一つの楽曲が、幾つもの世界を描き始める！譜面も世界観もまさに『MASH VP!』！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className={descTextClass}>프리 플레이 모드에서 곡을 선택하면, 매쉬업 전용 트랙이 잠금 해제됩니다!</div>
                    <div className={descTextClass}>서로 다른 음악 세계가 충돌하여 탄생한 한 곡!</div>
                    <div className={descTextClass}>이 차트와 테마는 'MASH VP!'의 진정한 매력을 보여줍니다.</div>
                </div>
        },
        characterIntroDiv: {
            "en":
                <div className="character-image-mobile">
                    <img src={characterIntroJp}></img>
                </div>,
            "jp":
                <div className="character-image-mobile">
                    <img src={characterIntroJp}></img>
                </div>,
            "kr":
                <div className="character-image-mobile">
                    <img src={characterIntroJp}></img>
                </div>,
        },
        earlyAccessSectionDiv: {
            "en":
                <div className="mashup-textbox">
                    <div className={descTextClass}>"MASH VP! Re:VISION" Early Access includes the following game modes from day one:</div>
                    <div className={descTextClass}>・FREE PLAY</div>
                    <div className={descTextClass}>・MASH UP</div>
                    <div className={descTextClass}>We will be adding more songs prior to the full version release,</div>
                    <div className={descTextClass}>which will include over 90 tracks upon launch!</div>
                    <div className={descTextClass}>This includes regular single tracks in “FREE PLAY” and special mashup tracks in “MASH UP!”</div>
                    <div className={descTextClass}>Additionally, we will be collecting and incorporating player feedback from Early Access</div>
                    <div className={descTextClass}>directly into the development of the full version.</div>
                    <div className={descTextClass}>Multiple updates are planned during Early Access, and we intend to add new modes such as</div>
                    <div className={descTextClass}>"GHOST BATTLE," where you can challenge other players' score replays, and</div>
                    <div className={descTextClass}>"CHALLENGE," which allows you to test your skills!</div>
                </div>,
            "jp":
                <div className="mashup-textbox">
                    <div className={descTextClass}>「MASH VP! Re:VISION」はアーリーアクセスでは</div>
                    <div className={descTextClass}>・FREE PLAY</div>
                    <div className={descTextClass}>・MASH UP</div>
                    <div className={descTextClass}>上記２つのモードをプレイいただけます！</div>
                    <div className={descTextClass}>フルバージョンリリース時までに楽曲を追加し、</div>
                    <div className={descTextClass}>フルバージョンリリース時には「FREE PLAY」の通常楽曲、</div>
                    <div className={descTextClass}>「MASH UP」のマッシュアップ楽曲を合わせて、90曲以上がプレイ可能に！</div>
                    <div className={descTextClass}>また、アーリーアクセスでは、プレイヤーの皆様からいただいたご意見を</div>
                    <div className={descTextClass}>開発に反映させることも目的として公開しております。</div>
                    <div className={descTextClass}>アップデートは複数回予定しており</div>
                    <div className={descTextClass}>他プレイヤーのスコアに挑む「GHOST BATTLE」や</div>
                    <div className={descTextClass}>自分の腕前を測る「CHALLENGE」といったモードを追加予定！</div>
                </div>,
            "kr":
                <div className="mashup-textbox">
                    <div className={descTextClass}>「MASH VP! Re:VISION」 얼리 액세스에서는</div>
                    <div className={descTextClass}>・FREE PLAY</div>
                    <div className={descTextClass}>・MASH UP</div>
                    <div className={descTextClass}>이 두 가지 모드를 플레이하실 수 있습니다!</div>
                    <div className={descTextClass}>정식 버전 출시 전까지 곡이 추가되며,</div>
                    <div className={descTextClass}>정식 출시 시점에는 "FREE PLAY"의 일반 곡과 "MASH UP"의 매쉬업 곡을 포함하여 90곡 이상을 플레이할 수 있게 됩니다!</div>
                    <div className={descTextClass}>또한, 얼리 액세스는 플레이어 여러분의 의견을 개발에 반영하는 것을 목표로 공개되었습니다.</div>
                    <div className={descTextClass}>여러 차례 업데이트가 예정되어 있으며,</div>
                    <div className={descTextClass}>다른 플레이어의 점수에 도전하는 "GHOST BATTLE" 모드나 자신의 실력을 테스트할 수 있는 "CHALLENGE" 모드가 추가될 예정입니다!</div>
                </div>
        }
    }


    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        stringToLocale(checkLocale())
    }, [locale])



    useEffect(() => {

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [window.innerWidth])


    function checkLocale(): string {
        const urlParam = searchParams.get("locale")
        const validParams = ["en", "jp", "kr"]
        if (urlParam) {
            if (validParams.includes(urlParam)) {
                setSearchParams({ locale: locale })
                return urlParam
            }
            else {
                setSearchParams({ locale: "en" })
                return "en"
            }
        }
        else {
            setSearchParams({ locale: "en" })
            return "en"
        }
    }

    function handleResize() {
        setCurrentWidth(window.innerWidth)
        setVideoWidth(window.innerWidth * 0.4)
        setVideoWidthMobile(window.innerWidth * 0.6)

        if (window.innerWidth > 750) {
            setDescTextClass("mashup-description-text-mobile")
        }
        else if (window.innerWidth > 600) {
            setDescTextClass("mashup-description-text-mobile-bp1")
        }
        else {
            setDescTextClass("mashup-description-text-mobile-bp2")
        }
    }

    function stringToLocale(stringLocale: string): Locale {
        switch (stringLocale) {
            case "en":
            case "jp":
            case "kr":
                return stringLocale
            default:
                return "en";
        }
    }

    function handleVoicePlay() {
        setRayPlaying(true);
    }




    return (
        currentWidth > 1200 ?
            <div className="mashup-page">
                <Header logoOverride="games" focusedArea="" isMobileAndNotMain={false} locale={locale}
                        setLocale={setLocale} videoEnded={true}/>
                <div className="title-images-mashup">
                    <div className="game-icon-container">
                        <img className="game-icon" src={gameLogoImage}></img>
                    </div>
                    <div className="catchcopy-container-mashup">
                        <img src={catchCopyImage} className="game-catchcopy-img"></img>
                    </div>
                    {divDatabase.mashupTitleTextDiv[locale]}
                </div>
                <div className="free-play-section">
                    <div className="free-play-lanes">
                        <img src={lanesImage}></img>
                    </div>
                    {divDatabase.freePlayImageDiv[locale]}
                    {divDatabase.freePlaySectionDiv[locale]}
                    <div className="mashup-page-videos">
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/Sjuqdy4Jx1U?si=Lo-0oMLat6d4dARQ"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/EX4nZ42kjyY?si=uI3Krl8mNA9fmyf4"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
                <div className="tutorial-section">
                    {divDatabase.tutorialImageDiv[locale]}
                    <div className="tutorial-subitems">
                        <div className="mashup-page-videos">
                            <iframe width={videoWidth} height={videoWidth * 0.5625}
                                    src="https://www.youtube.com/embed/heHi-8Z1Yik?si=BNU3L8LKWYe6UVYR"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                            {divDatabase.tutorialExampleDiv[locale]}
                        </div>
                    </div>
                    <div className="character-intro-section">
                        {/*                         <div className="voice-player">
                            <div className="">play button</div>
                            <div className="message-box"></div>
                        </div> */}
                        {divDatabase.characterIntroDiv[locale]}
                        {/*                         <div className="voice-player">
                            <div className="play-button" onClick={handleVoicePlay}>play lmao</div>
                            {
                                rayPlaying && <div className="subtitle-box">
                                    <div className="background-animation-subtitle"></div>
                                    <div className="cornerbox topleft"></div>
                                    <div className="cornerbox topright"></div>
                                    <div className="cornerbox bottomleft"></div>
                                    <div className="cornerbox bottomright"></div>
                                    <div className="subtitle-text-container">Shion... who's that? I'm Ray. Who are you confusing me for...? </div>
                                </div>
                            }

                        </div> */}
                    </div>
                </div>
                <div className="mashup-section">
                    {divDatabase.mashupImageDiv[locale]}
                    {divDatabase.mashupSectionDiv[locale]}
                    <div className="mashup-bgimage">
                        <img src={mashupBgImage}></img>
                    </div>
                    <div className="mashup-page-videos">
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/XZIfogVsatc?si=IZ3of1m1Db7ywVaS"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/mRlacAmacAA?si=kX5PCFPXkPlRJpOv"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
                <div className="early-access-section">
                    <div className="early-access-bgimages">
                        <img src={earlyAccessBgImage}></img>
                    </div>
                    {divDatabase.earlyAccessSectionDiv[locale]}
                </div>
                <div className="steam-logo-container">
                    <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/" className="steam-logo-div">
                        <img src={steamLogo}></img>
                    </a>
                </div>
                <Footer locale={locale}/>
            </div>
            :
            <div className="mashup-page-mobile">
                <HeaderMobile logoOverride="games" focusedArea="" isMobileAndNotMain={true} locale={locale}
                              setLocale={setLocale} videoEnded={true}/>
                <div className="title-images-mashup-mobile">
                    <div className="game-icon-container-mobile">
                        <img className="game-icon-mobile" src={gameLogoImage}></img>
                    </div>
                    <div className="catchcopy-container-mashup-mobile">
                        <img src={catchCopyImage} className="game-catchcopy-img-mobile"></img>
                    </div>
                    {mobileDivDatabase.mashupTitleTextDiv[locale]}
                </div>
                <div className="free-play-section-mobile">
                    <div className="free-play-title">
                        {mobileDivDatabase.freePlayImageDiv[locale]}
                        <div className="free-play-lanes">
                            <img src={lanesImage}></img>
                        </div>
                        {mobileDivDatabase.freePlaySectionDiv[locale]}
                        <div className="mashup-page-videos-mobile">
                            <iframe width={videoWidthMobile} height={videoWidth * 0.8}
                                    src="https://www.youtube.com/embed/Sjuqdy4Jx1U?si=Lo-0oMLat6d4dARQ"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                            <iframe width={videoWidthMobile} height={videoWidth * 0.8}
                                    src="https://www.youtube.com/embed/EX4nZ42kjyY?si=uI3Krl8mNA9fmyf4"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="tutorial-section-mobile">
                    {mobileDivDatabase.tutorialImageDiv[locale]}
                    <div className="mashup-page-videos-mobile">
                        <iframe width={videoWidthMobile} height={videoWidth * 0.8}
                                src="https://www.youtube.com/embed/heHi-8Z1Yik?si=BNU3L8LKWYe6UVYR"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                    {mobileDivDatabase.tutorialExampleDiv[locale]}
                </div>
                <div className="mashup-section-mobile">
                    {mobileDivDatabase.mashupImageDiv[locale]}
                    {mobileDivDatabase.mashupSectionDiv[locale]}
                    <div className="mashup-bgimage-mobile">
                        <img src={mashupBgImage}></img>
                    </div>
                    <div className="mashup-page-videos-mobile">
                        <iframe width={videoWidthMobile} height={videoWidth * 0.8}
                                src="https://www.youtube.com/embed/XZIfogVsatc?si=IZ3of1m1Db7ywVaS"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                        <iframe width={videoWidthMobile} height={videoWidth * 0.8}
                                src="https://www.youtube.com/embed/mRlacAmacAA?si=kX5PCFPXkPlRJpOv"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
                <div className="character-intro-section-mobile">
                    {mobileDivDatabase.characterIntroDiv[locale]}
                </div>
                <div className="early-access-section-mobile">
                    <div className="early-access-bgimages-mobile">
                        <img src={earlyAccessBgImage}></img>
                    </div>
                    {mobileDivDatabase.earlyAccessSectionDiv[locale]}
                </div>
                <div className="steam-logo-container-mobile">
                    <a href="https://store.steampowered.com/app/2928330/MASH_VP_ReVISION/" className="steam-logo-div-mobile">
                        <img src={steamLogo}></img>
                    </a>
                </div>
                <FooterMobile locale={locale}/>
            </div>

    )
}