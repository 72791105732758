import React, { useEffect, useState } from "react";
import "../Stylesheets/RecruitSection.css"

export default function RecruitItemContainerMobile(props: RecruitIContainerProps) {

    const locale = props.locale
    const titleText = props.post.positionTitle
    const dateText = props.post.date
    const currentWidth = props.currentWidth
    const jobDescriptionText = props.post.jobDescription
    const requiredSkillsText = props.post.requiredSkills
    const preferredSkillsText = props.post.preferredSkills

    const [topTextBoxClass, setTopTextBoxClass] = useState<string>("")
    const [topContainerClass, setTopContainerClass] = useState<string>("")

    const textDatabase: TextDatabase = {
        jobDescText: {
            "en" : "Job Description",
            "jp": "業務内容",
        },
        requiredSkillsText: {
            "en" : "Required Experience",
            "jp": "必須スキル・経験",
        }, 
        preferredSkillsText: {
            "en" : "Preferred Experience",
            "jp": "歓迎するスキル・経験",
        }
    }

    useEffect(() => {
        handleResize()
    }, [])
    useEffect(() => {
        handleResize()
    }, [currentWidth])

    function handleResize() {
        if (currentWidth > 600)
        {
            setTopTextBoxClass("recruit-top-textbox-mobile")
            setTopContainerClass("recruit-icontainer-top-mobile")
        }
        else if (currentWidth > 550) {
            
            setTopContainerClass("recruit-icontainer-top-mobile-bp1")
        }
        else {
            setTopContainerClass("recruit-icontainer-top-mobile-bp1")
            setTopTextBoxClass("recruit-top-textbox-mobile-bp1")
        }
    }
    
    return (
        <div className="recruit-item-container-mobile">
            <div className={topContainerClass}>
                <div className={topTextBoxClass}>
                    <div className="recruit-top-text-mobile">{titleText}</div>
                </div>
            </div>
            <div className="recruit-icontainer-bottom-mobile">
                <div className="recruit-detail-box-mobile">
                    <div className="recruit-bottom-title-mobile">{textDatabase.jobDescText[locale]}</div>
                    <div className="recruit-detail-desc-text-mobile" dangerouslySetInnerHTML={{__html: jobDescriptionText}}></div>
                </div>
                <div className="recruit-detail-box-mobile">
                    <div className="recruit-bottom-title-mobile">{textDatabase.requiredSkillsText[locale]}</div>
                    <div className="recruit-detail-desc-text-mobile" dangerouslySetInnerHTML={{__html: requiredSkillsText}}></div>
                </div>
                <div className="recruit-detail-box-mobile">
                    <div className="recruit-bottom-title-mobile">{textDatabase.preferredSkillsText[locale]}</div>
                    <div className="recruit-detail-desc-text-mobile" dangerouslySetInnerHTML={{__html: preferredSkillsText}}></div>
                </div>
            </div>
        </div>
    )
}